import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { ChamadoChecklist, FormToFilling } from "../components/Filling/Types";
import { AuthBodyType } from "./types";
import { ItemChecklist } from "./Checklists";

export type ChecklistAnonimoType = {
  abreChamado: {
    apos: boolean;
    durante: boolean;
  };
  dataAlteracao: number;
  dataCadastro: number;
  editavel: { cancela: boolean; clone: boolean };
  empresa: string;
  id: string;
  identificadorLista: string;
  itens: ItemChecklist;
  nome: string;
  preenchimentoDeslogado: boolean;
  preenchimentoWeb: boolean;
  status: boolean;
  userNome: string;
  versao: number;
};

type hierarquiaToSendType = {
  cliente: string;
  local: string;
  conjunto: string;
  unidade: string;
};

class Anonimo {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/anonimo`,
  });

  executaChecklistAnonimo = async (checklist: FormToFilling, ocultos?: string[]) => {
    try {
      const check = { ...checklist };
      const data: AxiosResponse = await this.api.post("/executaChecklistAnonimo", {
        checklist: check,
        ocultos,
      });
      return data.data.forms;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!')
        return error.response?.status;
      }
    }
  };

  listaHistoricoAnonimo = async (body: AuthBodyType, formId: string, inicio: number, fim: number) => {
    try {
      const data: AxiosResponse = await this.api.post("/listaHistoricoAnonimo", {
        ...body,
        formId,
        inicio,
        fim,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  listaModelosChecklistAnonimo = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/listaModelosChecklistAnonimo", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  editaChamadoExecucao = async (
    body: AuthBodyType,
    formId: string,
    inicio: number,
    chamados: ChamadoChecklist[],
    itemId?: number | boolean | null,
    subId?: number | boolean | null,
    hierarquia?: hierarquiaToSendType | null
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/editaChamadoExecucao", {
        ...body,
        formId,
        inicio,
        itemId,
        subId,
        chamados,
        hierarquia,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  executaChecklistLogado = async (
    body: AuthBodyType,
    hierarquia: {
      cliente: string;
      local: string;
      conjunto: string;
      unidade: string;
    },
    checklist: FormToFilling
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/executaChecklistLogado", {
        ...body,
        hierarquia,
        checklist,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default Anonimo;
