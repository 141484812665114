import { useTranslation } from 'react-i18next';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import ServiceTypes, { types } from '../../../service/ServiceTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import NovaNotificacao, { newNotificationType } from '../../../service/NovaNotificacao';
import { TextField } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@mui/material';

type props = {
  isEdit: boolean,
  notificationEdit: newNotificationType | undefined,
  setNotificationEdit: Function,
  openModal: boolean,
  setOpenModal: Function,
}

const serviceType = new ServiceTypes();
const notifService = new NovaNotificacao();

export default function NewNotificationsModal({
  isEdit,
  notificationEdit,
  setNotificationEdit,
  openModal,
  setOpenModal }: props) {
  const { t } = useTranslation('translation');

  const { data: servicesData, isLoading } = useQuery({
    queryKey: ['SERVICES_LIST'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: create } = useMutation({
    mutationKey: ['NEW_NOTIFICATION_MODEL'],
    mutationFn: async () => {
      toast.warn('Criando novo modelo de notificação')
      const token = await AttToken()
      if (token) {
        const response = await notifService.create(token, notificationEdit as newNotificationType)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_NOTIFICATION_LIST'])
      setOpenModal(!openModal)
      toast.success(`${t('notifications.ticket.ModeloCriado')}`)
    },
    onError: () => {

      toast.error(`${t('notifications.ticket.ErroAoCriarModelo')}`)
    }
  })

  const { mutate: edit } = useMutation({
    mutationKey: ['EDIT_NOTIF'],
    mutationFn: async () => {
      toast.warn(`Editando notificação`)
      const token = await AttToken()
      if (token) {
        const response = await notifService.edit(token, notificationEdit as newNotificationType)
        return response
      }
    },
    onSuccess: () => {
      setOpenModal(!openModal)
      queryClient.resetQueries(['GET_NOTIFICATION_LIST'])
      toast.success(`Notificação alterada`)
    },
    onError: () => {
      toast.error(`Erro ao alterar notificação`)
    }
  })

  return (
    <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>{!isEdit ? t('notifications.ticket.criarModelo') : t('notifications.ticket.editarModelo')}</GenericHeader>
        </header>
        <section className="modal-card-body" style={{ textAlign: 'initial' }}>
          <TextField
            fullWidth
            style={{ marginBottom: '10px' }}
            label={t('name')}
            value={notificationEdit ? notificationEdit?.name : undefined}
            onChange={(event) => setNotificationEdit({ ...notificationEdit, name: event.target.value })}
          />
          <TextField
            multiline
            fullWidth
            style={{ marginBottom: '10px' }}
            rows={2}
            maxRows={4}
            value={notificationEdit?.message}
            label={t('message')}
            onChange={(event) => setNotificationEdit({ ...notificationEdit, message: event.target.value })}
          />
          {isLoading ? <Skeleton className='column' /> :
            <Autocomplete
              id="combo-box-demo"
              sx={{ marginTop: '5px' }}
              defaultValue={notificationEdit?.serviceType ? {
                value: notificationEdit?.serviceType as string,
                label: servicesData?.find((ele: types) => ele.tipoId === notificationEdit?.serviceType)?.nome
              } : null}
              filterSelectedOptions
              options={
                servicesData
                  ?.sort((a: types, b: types) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((ele: any) => ({
                    label: ele.nome,
                    value: ele.tipoId,
                  }))}
              onChange={(event: any, newValue: { label: string, value: string } | null) => {
                setNotificationEdit({ ...notificationEdit, serviceType: newValue?.value })
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={t('notifications.ticket.tipoServico')} />}
            />
          }
        </section>
        <footer className="modal-card-foot" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            disabled={
              (!notificationEdit?.name?.length) ||
              (!notificationEdit?.message?.length) ||
              (!notificationEdit?.serviceType?.length)
            }
            className="button is-success is-small"
            onClick={() => {
              isEdit ?
                edit() :
                create()
            }}>{`${t('save')}`}</button>
          <button
            className="button is-warning is-small"
            onClick={() => {
              setOpenModal(!openModal)
            }}>
            {`${t('cancel')}`}
          </button>
        </footer>
      </div>
    </div>
  )
}
