import { useTranslation } from "react-i18next";
import "../styles.css";
import { useContext, useEffect, useState } from "react";
import PlanoDeAcao from "../../../service/PlanoDeAcao";
import { useMutation } from "@tanstack/react-query";
import AttToken from "../../../helpers/attToken";
import { toast } from "react-toastify";
import queryClient from "../../../service/query";
import { propsModalType } from "../PlanosDeAcaoComp";
import { FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { MdUploadFile } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";
import ModalImagePreview from "../../Reports/ModalDetailPrint/ModalImagePreview";
import { UserContext } from "../../../context/UserContext";
import { PiAsteriskFill } from "react-icons/pi";
import { Tooltip } from "@material-ui/core";

type props = {
  open: boolean,
  setOpen: Function,
  propsModal: propsModalType,
}

const planoService = new PlanoDeAcao();

export default function ModalEditaStatus({
  open,
  setOpen,
  propsModal,
}: props) {
  const { t } = useTranslation('translation');
  const { userData } = useContext(UserContext);
  const [status, setStatus] = useState<string>(propsModal.statusTask)
  const [comentario, setComentario] = useState<string>('');
  const [image, setImage] = useState<string | null>(null);
  const [openImage, setOpenImage] = useState<boolean>(false)
  const [fileType, setFileType] = useState<string>('')

  let encode = ''
  let decode = ''

  const { mutate, isLoading } = useMutation({
    mutationKey: ['EDIT_PLANO_STATUS'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const acao = {
          taskIndex: propsModal.taskIndex,
          comentario,
          status,
          planoId: propsModal.planoId,
          foto: image ? String(image) : undefined
        }
        const response = await planoService.changeStatusTask(token, acao)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_PLANOS_ACAO'])
      setOpen(!open)
      toast.success("Status alterado!")
    },
    onError: () => {
      toast.error("Erro ao alterar status")
    }
  })

  const verifyDisable = () => {
    if (isLoading) {
      return true
    } if (userData.userName !== propsModal.responsavelNome) {
      if (
        (propsModal.obrigatorioEvidencia && !comentario?.length)
        || (propsModal.obrigatorioEvidencia && !image?.length)
        || (status === 'pendente')) {
        return true
      }
    }

    return false
  }

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setImage(i)))
    }
  }

  const getFileType = (value: string) => {
    const fileName = value.substr(12, value?.length)
    const extension = fileName.split('.').pop()
    const imageTypes = ['JPEG', 'JPG', 'BMP', 'GIF', 'TIFF', 'HEIF', 'RAW', 'PSD', 'SVG', 'PNG'];
    if (imageTypes?.find((el) => el === String(extension).toUpperCase())) {
      return (
        <>
          <div>
            <button
              onClick={(e) => { e.preventDefault(); setImage(null) }}
              className="button is-small is-danger">
              <FaTrash />
            </button>
            <button
              className="button is-small is-info"
              onClick={() => setOpenImage(!openImage)}>
              <FaEye />
            </button>
          </div>
          <div>
            <img
              src={String(image)}
              className="imgStatus"
              alt="foto"
            />
          </div>
          <span className="tag" style={{ fontSize: '12px', color: 'gray' }}>{fileName}</span>
        </>
      )
    } else {
      return (
        <>
          <div>
            <button
              onClick={() => setImage(null)}
              className="button is-small is-danger">
              <FaTrash />
            </button>
          </div>
          <div className="documentStyle">
            <div style={{ fontSize: '12px', paddingLeft: "5px", fontWeight: '700', position: 'absolute' }}>
              {extension}
            </div>
            <FaRegFile style={{ padding: 0, margin: 0 }} />
          </div>
          <span className="tag" style={{ fontSize: '12px', color: 'gray' }}>{fileName}</span>
        </>
      )
    }
  }

  return (
    <>
      {openImage &&
        <ModalImagePreview
          image={String(image)}
          open={openImage}
          setOpen={setOpenImage}
        />}
      <div className={`modal ${open ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Editar status</p>
            <button onClick={() => setOpen(!open)} className="delete" aria-label={t('close')} />
          </header>
          <section className="modal-card-body">
            <div className="control">
              {propsModal?.logado === propsModal?.responsavel &&
                <>
                  <label className="label">
                    <input
                      disabled
                      checked={status === 'pendente'}
                      onChange={() => setStatus('pendente')}
                      type="radio"
                      name="status"
                    />
                    &nbsp;<span className="tag is-warning">Pendente</span>&nbsp;
                    {(status === 'pendente') &&
                      <Tooltip title='Status precisa ser diferente de pendente'>
                        <span>
                          <PiAsteriskFill style={{ color: '#F14668' }} />
                        </span>
                      </Tooltip>}
                  </label>
                  <label className="label">
                    <input
                      checked={status === 'emExecucao'}
                      onChange={() => setStatus('emExecucao')}
                      type="radio"
                      name="status" />
                    &nbsp;<span className="tag is-info">Em execucao</span>
                  </label>
                  <label className="label">
                    <input
                      checked={status === 'concluido'}
                      onChange={() => setStatus('concluido')}
                      type="radio"
                      name="status"
                    />
                    &nbsp;<span className="tag is-success is-light">Concluído</span>
                  </label>
                </>
              }
              {
                (propsModal?.logado === propsModal?.criador) &&
                <>
                  <label className="label">
                    <input
                      checked={status === 'aprovado'}
                      onChange={() => setStatus('aprovado')}
                      type="radio"
                      name="status"
                      disabled={propsModal.statusTask !== 'concluido'}
                    />
                    &nbsp;<span className="tag is-success">Aprovado</span>
                  </label>
                  <label className="label">
                    <input
                      checked={status === 'rejeitado'}
                      onChange={() => setStatus('rejeitado')}
                      type="radio"
                      name="status"
                      disabled={propsModal.statusTask !== 'concluido'}
                    />
                    &nbsp;<span className="tag is-danger">Rejeitado</span>
                  </label>
                </>
              }
            </div>
            <label>
              Adicionar comentário:&nbsp;
              {(userData.userName !== propsModal.responsavelNome) && (propsModal.obrigatorioEvidencia && comentario === '') &&
                <Tooltip title='É obrigatório um comentário para alterar este status'>
                  <span>
                    <PiAsteriskFill style={{ color: '#F14668' }} />
                  </span>
                </Tooltip>
              }
              <textarea value={comentario} onChange={(event) => setComentario(event.target.value)} className="textarea" />
            </label>
            <label style={{ display: 'flex', flexDirection: 'column' }}>
              {(userData.userName !== propsModal.responsavelNome) && <>
                <p>
                  Adicionar arquivo:
                  {(propsModal.obrigatorioEvidencia && image === null) &&
                    <Tooltip title='É obrigatório um arquivo para alterar este status'>
                      <span>
                        <PiAsteriskFill style={{ color: '#F14668' }} />
                      </span>
                    </Tooltip>
                  }
                </p>
                <div>
                  {image === null ?
                    <div className="divImageAndWarning">
                      <span className='button is-success is-small'>
                        <input
                          value={undefined}
                          id="img"
                          name="img"
                          className="file-input"
                          type="file"
                          accept="image/*, video/*, .doc, .docx, .xls, .xlsx, audio/*, .pdf, .txt, .json"
                          onChange={(e) => {
                            const files: any = e.target.files;
                            if (files) {
                              if (files[0].size > 128000000) {
                                toast.warn('Arquivo muito grande!');
                                e.target.files = null;
                                e.target.value = '';
                              } else {
                                renderImages(e.target.files)
                                setFileType(e.target.value)
                              }
                            }
                          }}
                        />
                        {t('users.userRegister.selectMessage')}&nbsp;
                        <MdUploadFile />
                      </span>
                    </div>
                    :
                    <>
                      {getFileType(fileType)}
                    </>
                  }
                </div>
              </>}
            </label>
          </section>
          <footer className="modal-card-foot">
            <div className="buttons">
              <button disabled={verifyDisable()} onClick={() => mutate()} className="button is-success is-small">{t('save')}</button>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}
