import { Accordion, AccordionSummary } from "@material-ui/core";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdOutlineExpandMore } from "react-icons/md";
import { Label } from "../../Notifications/style/SingleNotificationStyle";
import { StyledTextArea } from "../../Filling/Modals/Styles";
import ReactDatePicker from "react-datepicker";
import { useContext, useMemo, useState } from "react";
import { ChamadoChecklist, chamadoConfigType, chamadoConfigTypeSubitem } from "../../Filling/Types";
import { addTicketType } from "./ModalDetailPrint";
import { useTranslation } from "react-i18next";
import Anonimo from "../../../service/Anonimo";
import AttToken from "../../../helpers/attToken";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import Clients, { ClientType } from "../../../service/Clients";
import { chamadosAssociacoesType } from "../../Checklists/Modals/FormModal/FillingConfig";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserContext } from "../../../context/UserContext";
import { Skeleton } from "@mui/material";
import { ConjuntoType, PlaceType, UnidadeType } from "../../../service/Places";
import { ChecklistReportType } from "../../../service/Reports";

type props = {
  chamado: ChamadoChecklist
  index: number,
  setChamados: Function,
  chamados: ChamadoChecklist[],
}

function ChamadoCard({ chamado, index, setChamados, chamados }: props) {
  const [instrucao, setInstrucao] = useState(chamado.textChamado || '');
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState<Date | number | undefined>(chamado.dateChamado as number || undefined);

  const { userData } = useContext(UserContext);

  return (
    <div key={index} style={{ margin: '20px', backgroundColor: '#F2F2F2', width: `${chamado.adicionadoApos ? '68%' : '70%'}`, alignSelf: 'center' }}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore color="#18A689" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => {
              setExpand(!expand)
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h1 style={{ fontFamily: 'arial', fontSize: '12px', fontWeight: 500, color: 'gray' }}>{chamado.selecionado ? chamado.selecionado.nome : 'Nome não encontrado'}</h1>
          </div>
        </AccordionSummary>
        <div style={{ padding: '10px' }}>
          <Label style={{ display: 'flex', flexDirection: 'column' }}>
            Instruções do chamado:
            <StyledTextArea value={instrucao} className='textarea' onChange={(e) => {
              setInstrucao(e.target.value)
              chamados[index].textChamado = e.target.value;
              setChamados([...chamados]);
            }} disabled={!chamado.adicionadoApos} />
          </Label>
          <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Prazo do chamado:
            {/* após form */}
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              disabled={!chamado.adicionadoApos}
              showTimeSelect={true}
              onKeyDown={(e) => e.preventDefault()}
              timeIntervals={1}
              portalId="root-portal"
              selected={data === undefined ? undefined : new Date(data)}
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='inputDate input'
              required
              onChange={
                (date: Date) => {
                  setData(date);
                  chamados[index].dateChamado = new Date(date).getTime();
                  setChamados([...chamados]);
                }
              }
            />
          </Label>
          {userData.migrado && (
            <Label>
              Prioridade:
              <select
                defaultValue={chamados[index].prioridade}
                onChange={(e) => {
                  const prioridade = e.target.value as 'alta' | 'media' | 'baixa';
                  chamados[index].prioridade = prioridade;
                  setChamados([...chamados]);
                }}
              >
                <option value="alta">Alta</option>
                <option value="media">Média</option>
                <option value="baixa">Baixa</option>
              </select>
            </Label>
          )}
          {data === undefined && <span
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              color: 'red',
              fontWeight: 700,
              fontSize: '12px'
            }}>
            **É obrigatório informar uma data para abertura do chamado
          </span>}
        </div>
      </Accordion>
    </div>
  )
}

type ModalEditTicketsProps = {
  chamados: ChamadoChecklist[],
  open: boolean,
  setOpen: Function,
  currentElement: addTicketType,
  chamadosConfig: chamadoConfigTypeSubitem | chamadoConfigType,
  setChamados: Function,
  ClearAndExit: Function,
  formId: string,
  inicio: number,
  chamadosObj: ChamadoChecklist[] | undefined,
  selected: ChecklistReportType
}

export default function ModalEditTicketsAfter({
  chamados,
  open,
  setOpen,
  currentElement,
  chamadosConfig,
  setChamados,
  ClearAndExit,
  formId,
  inicio,
  chamadosObj,
  selected
 }: ModalEditTicketsProps) {
  const { t } = useTranslation('translation');

  const filterFunction = (index: number) => {
    setChamados((prev: ChamadoChecklist[]) => {
      return prev.filter((_e, i) => i !== index);
    });
  }

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setOpen()}></div>
      <div style={{ backgroundColor: 'white', width: '75%', display: 'flex', flexDirection: 'column' }} className="modal-content">
        <Label style={{ width: '75%', alignSelf: 'center', marginTop: '20px', marginBottom: '20px' }}>Adicione um chamado:
          <select className="select is-fullwidth" defaultValue={""} onChange={(event) => {
            const chamado = JSON.parse(event.target.value);
            chamados.push({ selecionado: chamado, adicionadoApos: true, prioridade: 'baixa' });
            setChamados([...chamados]);
            event.target.value = ""
          }}>
            <option value="" disabled>{t('select')}</option>
            {
              chamadosConfig.tipoChamado?.map(e => <option value={JSON.stringify(e)}>{e.nome}</option>)
            }
          </select>
        </Label>
        {chamados?.map((e, i) => (
          <div
            key={`INDEX${i}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'center',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <ChamadoCard
              chamado={e}
              index={i}
              setChamados={setChamados}
              chamados={chamados}
            />
            {e.adicionadoApos &&
              <AiFillCloseCircle
                color='red'
                style={{ fontSize: '20px' }}
                onClick={() => {
                  filterFunction(i);
                }} />
            }
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px', backgroundColor: '#F3F3F3' }}>
          <ConfirmationModal
            formId={formId}
            inicio={inicio}
            itemId={currentElement?.itemIndex!!!}
            subId={currentElement?.subItemIndex!!!}
            chamados={chamados}
            close={setOpen}
            setChamados={setChamados}
            selected={selected}
          // data={e}
          />
          <button className="button is-light" onClick={() => {
            setOpen();
          }}>{t('close')}</button>
          <button className="button is-danger" onClick={() => { setChamados(chamados.filter((ele) => ele.dateChamado || ele.textChamado)); setOpen() }}>{t('cancel')}</button>
        </div>
      </div>
    </div>
  )
}

type confirmationProps = {
  formId: string,
  inicio: number,
  itemId: number | boolean,
  subId: number | boolean,
  chamados: ChamadoChecklist[] | undefined,
  close: Function,
  setChamados: Function,
  selected: ChecklistReportType
  // data: number | Date | undefined
}

function ConfirmationModal({ formId, inicio, itemId, subId, chamados, close, setChamados, selected }: confirmationProps) {
  const ocultos = selected.camposOcultos;

  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chamadoAssociacoes, setChamadoAssociacoes] = useState<chamadosAssociacoesType | null>({
    cliente: ocultos ? ocultos.cliente || null : null,
    local: ocultos ? ocultos.local || null : null,
    conjunto: ocultos ? ocultos.conjunto || null : null,
    unidade: ocultos ? ocultos.unidade || null : null,
  });

  const { hierarchyLabel, userData } = useContext(UserContext);

  const anonimoService = useMemo(() => new Anonimo(), []);
  const clienteService = useMemo(() => new Clients(), []);


  const ArrumaChecklistsApos = () => {
    const newChamados = chamados?.map(e => {
      if (e.adicionadoApos) delete e.adicionadoApos;
      return e;
    })
    newChamados && setChamados([...newChamados])
  }

  const { data: hierarquia, isLoading } = useQuery({
    queryKey: ['HIERARQUIA'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const result = await clienteService.getAllHierarchy(token);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const findName = (key: 'clientes' | 'conjuntos' | 'locais', id: string): string => {
    const selected = hierarquia ? hierarquia[key].find((el: ConjuntoType | ClientType | PlaceType) => el.id === id) : { nome: 'Nome não encontrado', id: 'A' }
    return selected ? selected.nome : 'Nome não encontrado';
  }

  return (
    <>
      <button disabled={!chamados?.every(e => e.dateChamado)} className="button is-success" onClick={() => {
        setOpen(true);
      }}>{t('save')}</button>
      <div className={`modal ${open ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div style={{ backgroundColor: userData.migrado ? 'lightgray' : 'white', width: '50%', display: 'flex', flexDirection: 'column', borderRadius: '10px' }} className="modal-content">
        {userData.migrado && (
          <div style={{  padding: '5px 5px 0 0', alignSelf: 'center', backgroundColor: 'white', width: '100%' }}>
            <h1 style={{ fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center', color: '#515151' }}>Por favor confirme se a hierarquia de abertura de chamado está correta</h1>
          </div>
        )}
        {userData.migrado ? (
            <Accordion style={{ marginBottom: '30px', width: '85%', margin: '50px', alignSelf: 'center' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <p>Configuração de Hierarquia</p>
              </AccordionSummary>
              {isLoading ? (<Skeleton/>) : (
              <div style={{ margin: '10px' }}>
                {chamadoAssociacoes && chamadoAssociacoes.cliente && (
                  <span style={{ fontSize: '0.7em', wordBreak: 'break-word', maxWidth: '100%', fontWeight: 'bold', color: '#3C3C3C', backgroundColor: '#FFE08A', padding: '3.5px' }}>
                    {
                      chamadoAssociacoes.cliente && !chamadoAssociacoes.local ? (
                      `Apenas as equipes que atendem TODOS os ${hierarchyLabel.conjunto} de TODOS os ${hierarchyLabel.local} do ${hierarchyLabel.cliente} "${findName('clientes', chamadoAssociacoes.cliente)}" receberão chamados`
                      ) : chamadoAssociacoes.cliente && chamadoAssociacoes.local && !chamadoAssociacoes.conjunto ? (
                        `Apenas as equipes que atendem TODOS os ${hierarchyLabel.conjunto} do ${hierarchyLabel.local} "${findName('locais', chamadoAssociacoes.local)}" receberão chamados`
                      ) : chamadoAssociacoes.cliente && chamadoAssociacoes.local && chamadoAssociacoes.conjunto ? (
                        `Apenas as equipes que atendem o ${hierarchyLabel.conjunto} "${findName('conjuntos', chamadoAssociacoes.conjunto)}" receberão chamados`
                      ) : ('')
                    }
                  </span>
                )}
                <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ width: '15%' }}>{hierarchyLabel.cliente}:&nbsp;</p>
                  <select
                    className='select is-fullwidth'
                    onChange={(e) => {
                      const clienteId = e.target.value;
                      setChamadoAssociacoes({cliente: clienteId, conjunto: null, local: null, unidade: null})
                    }}
                    value={chamadoAssociacoes ? chamadoAssociacoes.cliente || "" : "" }
                  >
                    <option disabled value="">Todos</option>
                    <option value="all">Todos</option>
                    {hierarquia.clientes && hierarquia.clientes.map((cl: ClientType) => (
                      <option value={cl.id}>{cl.nome}</option>
                    ))}
                  </select>
                </Label>
                <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ width: '15%' }}>{hierarchyLabel.local}:&nbsp;</p>
                  <select
                    className='select is-fullwidth'
                    onChange={(e) => {
                      const id = e.target.value;
                      setChamadoAssociacoes(prev => prev && {...prev, local: id, conjunto: null, unidade: null})
                    }}
                    value={chamadoAssociacoes ? chamadoAssociacoes.local || "" : "" }
                    disabled={!chamadoAssociacoes || !chamadoAssociacoes.cliente}
                  >
                    <option disabled value="">Todos</option>
                    <option value="all">Todos</option>
                    {chamadoAssociacoes && chamadoAssociacoes.cliente && hierarquia.locais &&
                      hierarquia.locais.filter((cl: PlaceType) => cl.cliente === chamadoAssociacoes.cliente).map((cl: PlaceType) => (
                      <option value={cl.id}>{cl.nome}</option>
                    ))}
                  </select>
                </Label>
                <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ width: '15%' }}>{hierarchyLabel.conjunto}:&nbsp;</p>
                  <select
                    className='select is-fullwidth'
                    onChange={(e) => {
                      const id = e.target.value;
                      setChamadoAssociacoes(prev => prev && {...prev, conjunto: id, unidade: null})
                    }}
                    value={chamadoAssociacoes ? chamadoAssociacoes.conjunto || "" : "" }
                    disabled={!chamadoAssociacoes || !chamadoAssociacoes.local}
                  >
                    <option disabled value="">Todos</option>
                    <option value="all">Todos</option>
                    {chamadoAssociacoes && chamadoAssociacoes.local && hierarquia.conjuntos &&
                      hierarquia.conjuntos.filter((cnj: ConjuntoType) => cnj.local === chamadoAssociacoes.local).map((cnj: ConjuntoType) => (
                      <option value={cnj.id}>{cnj.nome}</option>
                    ))}
                  </select>
                </Label>
                <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ width: '15%' }}>{hierarchyLabel.unidade}:&nbsp;</p>
                  <select
                    className='select is-fullwidth'
                    onChange={(e) => {
                      const id = e.target.value;
                      setChamadoAssociacoes(prev => prev && {...prev, unidade: id})
                    }}
                    value={chamadoAssociacoes ? chamadoAssociacoes.unidade || "" : "" }
                    disabled={!chamadoAssociacoes || !chamadoAssociacoes.conjunto}
                  >
                    <option disabled value="">Todos</option>
                    <option value="all">Todos</option>
                    {chamadoAssociacoes && chamadoAssociacoes.conjunto && hierarquia.unidades &&
                      hierarquia.unidades.filter((uni: UnidadeType) => uni.conjunto === chamadoAssociacoes.conjunto).map((uni: UnidadeType) => (
                      <option value={uni.id}>{uni.nome}</option>
                    ))}
                  </select>
                </Label>
              </div>
              )}
            </Accordion>
          ) : (
            <h1 style={{ fontFamily: 'sans-serif', fontWeight: 700}}>Tem Certeza? Essa ação é irreversivel!</h1>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px', backgroundColor: '#F3F3F3' }}>
            <button
              disabled={loading || isLoading}
              className="button is-success"
              onClick={async () => {
                setLoading(true);
                toast.warn('Salvando Chamados');
                const token = await AttToken();
                if (token) {
                  await anonimoService.editaChamadoExecucao(token, formId, inicio, chamados!!!, itemId, subId, {
                    cliente: chamadoAssociacoes?.cliente || 'all',
                    local: chamadoAssociacoes?.local || 'all',
                    conjunto: chamadoAssociacoes?.conjunto || 'all',
                    unidade: chamadoAssociacoes?.unidade || 'all',
                  });
                  toast.success('Chamados Disparados!');
                  ArrumaChecklistsApos();
                  setOpen(false);
                  close();
                } else toast.error('Token não encontrado');
                setLoading(false);
              }}>{t('yes')}</button>
            <button className="button is-danger" onClick={() => setOpen(false)} disabled={loading || isLoading}>{t('no')}</button>
          </div>
        </div>
      </div>
    </>
  )
}
