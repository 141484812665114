import React, { useContext, useEffect, useMemo, useState, ChangeEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import Users from '../../service/Users';
import { Label, RoundImage } from '../../StyledComponents/Input/generic';
import { GenericHeader, ModalCard, ModalCardbody } from '../../StyledComponents/Modal/generic';
import { Card, GenericText, TitleText } from '../Teams/style/RegisteredTeamsStyle';
import { AproverContainer, FileInput } from './style/UserRegisterStyle';
import AttToken from '../../helpers/attToken';
import queryClient from '../../service/query';
import { userTag } from './TagControll';
import ProfilePic from "../../assets/images/profile.png";
import { useMutation, useQuery } from '@tanstack/react-query';
import ModalDelete from './ModalDelete';
import AvatarEditor from 'react-avatar-editor';
import TextField from '@material-ui/core/TextField';
import { Slider } from '@material-ui/core';
import Teams, { SimpleTeam } from '../../service/Teams';
import { Autocomplete, Skeleton } from '@mui/material';
import { useMask } from '@react-input/mask';


export type editUserType = {
  id: string,
  nome: string,
  email: string,
  contato: string,
  status: string,
  perfil: string,
  matricula: string,
  aliasname?: string,
  aprovador: boolean,
  empresa: string,
  foto: string,
  tags: string[],
  listaEquipesNomes?: string[],
  listaEquipes: string[],
  gestorPA?: boolean,
  inspetorPA?: boolean,
  adm?: boolean
}

const TeamsService = new Teams();

type autoCompleteType = {
  label: string,
  value: string,
}

const userService = new Users()

export default function ChangeUserModal({ selectedUser, userTags }: { selectedUser: editUserType, userTags: userTag[] }) {
  const avatarEditorRef = useRef<AvatarEditor | null>(null);
  const inputRef = useMask({ mask: '(__) ____-_____', replacement: '_' });

  const { openModal, popUp, userList, escape, setUserList, acessoPlanoDeAcao, userData } = useContext(UserContext);
  const [canReset, setCanReset] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [role, setRole] = useState('');
  const [scale, setScale] = useState<number>(1);
  const [editPicture, setEditPicture] = useState(false);
  const [changedUser, setChangedUser] = useState<editUserType>({ ...selectedUser });
  const [equipes, setEquipes] = useState<autoCompleteType[] | null>([] as autoCompleteType[]);
  const [tags, setTags] = useState<autoCompleteType[] | null>([] as autoCompleteType[]);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_TEAMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await TeamsService.getSimpleTeams(token);
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const [openDelete, setOpenDelete] = useState(false);

  const { t } = useTranslation('translation');

  useEffect(() => {
    setFirstRender(false);
    const userDataLocal = localStorage.getItem('userDataLocal');
    if (userDataLocal) {
      const { role } = JSON.parse(userDataLocal)
      setRole(role);
    }
  }, [firstRender, changedUser]);

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  useEffect(() => {
    if (canReset) {
      setChangedUser({
        ...selectedUser,
      })
      setCanReset(false);
    }
  }, [canReset, selectedUser, userList, changedUser]);

  const profileMocks = [
    { label: t('profiles.manager'), value: 'Gerente' },
    { label: t('profiles.coordinator'), value: 'Gestor' },
    { label: 'Supervisor', value: 'Supervisor' },
    { label: t('profiles.user'), value: 'Usuario' }
  ]

  const { mutate: onSubmit } = useMutation({
    mutationKey: ['CHANGE_USER'],
    mutationFn: async () => {
      try {
        toast.warn('Alterando usuário!')
        const token = await AttToken()
        if (token) {
          const aliasName = changedUser.id
          const editedUser = { ...changedUser }
          delete editedUser?.aliasname
          delete editedUser.listaEquipesNomes
          const response = await userService.changeUser(token, String(aliasName), editedUser)
          queryClient.resetQueries(['Users'])
          openModal()
          return response
        }
      } catch (err) {
        toast.error('Erro ao alterar usuário')
      }
    },
    onSuccess: () => {
      toast.success('Usuário alterado com sucesso!')
    }
  })

  const { mutate } = useMutation({
    mutationKey: ['DELETE_USER'],
    mutationFn: async (value: string) => {
      toast.warn(`Excluindo ${changedUser.nome}`)
      const token = await AttToken()
      if (token) {
        await userService.deleteUser(token, value)
        setUserList(userList && userList?.filter(e => e.id !== value))
        toast.warn(`Usuário ${changedUser.nome} deletado`)
        openModal();
      }
    }
  })

  const closeDelete = async () => {
    setOpenDelete(false);
  }

  const handleChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setScale(newValue);
    }
  };

  const { mutate: reset } = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async (value: string) => {
      const token = await AttToken();
      if (token) {
        const response = await userService.resetUserPassword(token, value)
        openModal();
        if (response) {
          toast.success(`${t('users.userChangeModal.resetMsg')}`)
        }
      }
    }
  })
  function adminUser() {
    if ((role === 'Administrador') || (role === 'Gestor')) {
      return (
        <div>
          <label className='checkbox is-small' style={{ fontSize: '0.8em' }}>
            <input
              type='checkbox'
              defaultChecked={changedUser.adm}
              onChange={(e) => {
                setChangedUser({ ...changedUser, adm: e.target.checked })
              }}
            />
            &nbsp;Usuário Administrador
          </label>
        </div>
      )
    }
  }

  useEffect(() => {
    if (selectedUser && data && userTags) {
      const userEquipes = data?.filter((ele: SimpleTeam) => selectedUser.listaEquipes?.includes(ele.id))
      const tags = userTags?.filter((ele: userTag) => selectedUser.tags?.includes(ele.id))
      const selectedTags = tags?.map((ele: userTag) => ({
        label: ele.name,
        value: ele.id,
      }))
      const team = userEquipes?.map((ele: SimpleTeam) => ({
        label: ele.nome,
        value: ele.id,
      }))
      setEquipes(team)
      setTags(selectedTags)
    }
  }, [selectedUser, userTags, data])

  const optionsStatus = [
    { label: t('active'), value: "ativo" },
    { label: t('inactive'), value: "inativo" },
  ];

  return (
    <Card>
      <TitleText>
        {userList && (
          <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
            <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
            <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
              <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
                <GenericHeader>
                  {t('users.userChangeModal.alterData')}
                </GenericHeader>
                <GenericText>
                  {t('users.userChangeModal.beConf')}
                </GenericText>
              </header>
              <ModalCardbody className="modal-card-body">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {(!editPicture)
                      ? (<RoundImage src={!changedUser.foto || changedUser.foto === "  "
                        ? ProfilePic : changedUser.foto} alt="" className='user-img' />)
                      :
                      <>
                        <AvatarEditor
                          image={changedUser.foto}
                          width={200}
                          height={200}
                          border={10}
                          borderRadius={200}
                          color={[243, 243, 244, 0.6]} // RGBA
                          scale={scale}
                          rotate={0}
                          ref={avatarEditorRef}
                        />
                        <span style={{ marginTop: '15px' }}>Zoom:</span>
                        <Slider
                          aria-label="Always visible"
                          defaultValue={100}
                          step={0.1}
                          min={1}
                          max={5}
                          value={scale}
                          style={{ width: '30%', color: '#47aa8d' }}
                          onChange={handleChange}
                        />
                      </>
                    }
                    {!editPicture ?
                      <button onClick={() => setEditPicture(true)} className='button is-small'>{t('editPic')}</button>
                      : <>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
                          <FileInput
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              e.target.files && getBase64(e.target.files[0], (base64) => {
                                setChangedUser({ ...changedUser, foto: base64 })
                              })
                            }}
                            theme={t('users.userRegister.selectMessage')}
                          />
                        </div>
                        <button className='button is-small is-warning' onClick={() => setEditPicture(false)}>{t('cancel')}</button>
                      </>
                    }
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <Label>{t('loginScreen.username')}:</Label>
                        <p>{changedUser.id}</p>
                      </div>
                      {adminUser()}
                    </div>
                    <Label>Email:</Label>
                    <p>{changedUser.email}</p>
                  </div>
                  <div className='columns'>
                    <div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        style={{ marginBottom: '10px' }}
                        label={t('name')}
                        value={changedUser.nome}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setChangedUser({ ...changedUser, nome: e.target.value })
                        }}
                      />
                      <TextField
                        style={{ marginBottom: '10px' }}
                        label={t('registration')}
                        inputProps={{ maxLength: 20 }}
                        value={changedUser.matricula}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setChangedUser({ ...changedUser, matricula: e.target.value })
                        }}
                      />
                      <Autocomplete
                        disableClearable
                        sx={{ marginBottom: '10px' }}
                        value={changedUser.status ? {
                          label: optionsStatus?.find((ele: { label: string, value: string }) => ele.value === changedUser.status)?.label as string,
                          value: changedUser.status
                        } : undefined}
                        options={(optionsStatus?.map((option) => ({
                          label: option.label,
                          value: option.value,
                        })))}
                        renderInput={(params) => <TextField {...params} label={t('status')} />}
                        onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, deatails: any) => {
                          setChangedUser({ ...changedUser, status: newValue?.value as string })
                        }}
                      />
                    </div>
                    <div className='column'>
                      <Autocomplete
                        disableClearable
                        sx={{ marginBottom: '10px', marginTop: '4px' }}
                        disabled={changedUser.perfil === 'Administrador'}
                        value={changedUser.perfil ? {
                          label: changedUser.perfil === 'Administrador' ? 'Administrador' : String(profileMocks?.find((ele: { value: string, label: string }) => ele.value === changedUser.perfil)?.label),
                          value: changedUser.perfil
                        } : undefined}
                        options={(profileMocks?.map((option: { value: string, label: string }) => ({
                          label: option.label,
                          value: option.value,
                        })))}
                        renderInput={(params) => <TextField {...params} label={t('profile')} />}
                        onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, deatails: any) => {
                          setChangedUser({ ...changedUser, perfil: newValue?.value as string })
                        }}
                      />
                      <div>
                        <TextField
                          value={changedUser.contato}
                          onChange={(even: React.ChangeEvent<HTMLInputElement>) => setChangedUser({ ...changedUser, contato: even.target.value })}
                          label={t('tel')}
                          inputRef={inputRef}
                        />
                      </div>
                      <AproverContainer>
                        <Label>{t('approver')}</Label>
                        <input type="checkbox"
                          checked={changedUser.aprovador}
                          onChange={() => setChangedUser({ ...changedUser, aprovador: !changedUser.aprovador })} />
                      </AproverContainer>
                      {acessoPlanoDeAcao && (
                        <>
                          <AproverContainer>
                            <Label>Gestor de plano de ação</Label>
                            <input type="checkbox"
                              checked={changedUser.gestorPA}
                              onChange={() => setChangedUser({ ...changedUser, gestorPA: !changedUser.gestorPA })} />
                          </AproverContainer>
                          <AproverContainer>
                            <Label>Inspetor de planos de ação</Label>
                            <input type="checkbox"
                              checked={changedUser.inspetorPA}
                              onChange={() => setChangedUser({ ...changedUser, inspetorPA: !changedUser.inspetorPA })} />
                          </AproverContainer>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='column'>
                    {isLoading ? <Skeleton /> :
                      <Autocomplete
                        value={tags ? tags : undefined}
                        defaultValue={tags ? tags : undefined}
                        className='column'
                        multiple
                        noOptionsText={'Sem tags correspondentes'}
                        size="small"
                        options={userTags
                          ?.filter((elem: userTag) => !tags?.some(tag => tag.value === elem.id))
                          ?.sort((a: userTag, b: userTag) => { return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1 })
                          ?.map((option: userTag) => ({
                            label: option.name,
                            value: option.id,
                          }))}
                        onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                          setTags(newValue)
                          let tagsArray = [] as string[]
                          newValue?.forEach((ele) => {
                            tagsArray.push(ele.value)
                          })
                          setChangedUser({ ...changedUser, tags: tagsArray })
                        }}
                        renderInput={(params) => <TextField {...params} label={'Selecione as tags'} />}
                      />
                    }
                  </div>
                  <div className='column'>
                    {isLoading ? <Skeleton /> :
                      <Autocomplete
                        value={equipes ? equipes : undefined}
                        defaultValue={equipes ? equipes : undefined}
                        className='column'
                        multiple
                        noOptionsText={'Sem equipes correspondentes'}
                        size="small"
                        options={data
                          ?.filter((elem: SimpleTeam) => !equipes?.some(equipe => equipe.value === elem.id))
                          ?.sort((a: SimpleTeam, b: SimpleTeam) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                          ?.map((option: SimpleTeam) => ({
                            label: option.nome,
                            value: option.id,
                          }))}
                        onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                          let users = [] as string[]
                          setEquipes(newValue)
                          newValue?.forEach((ele) => {
                            users.push(ele.value)
                          })
                          setChangedUser({ ...changedUser, listaEquipes: users })
                        }}
                        renderInput={(params) => <TextField {...params} label={t('Reports.multipleTeamSelect')} />}
                      />
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <button
                        type='submit'
                        className="button is-success is-small is-fullwidth"
                        onClick={(event) => {
                          event.preventDefault()
                          openModal()
                          onSubmit()
                        }}
                      >
                        {t('save')}
                      </button>
                    </div>
                    <div>

                      <button
                        type="button"
                        onClick={() => {
                          setCanReset(true);
                          openModal();
                        }}
                        className="button is-small is-warning is-fullwidth"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => reset(changedUser.id)}
                        className="button is-info is-small is-fullwidth"
                      >
                        {t('users.userChangeModal.resetPassword')}
                      </button>
                    </div>
                    {
                      role === 'Administrador' &&
                      <button
                        type='button'
                        className='button is-small is-danger'
                        onClick={() => setOpenDelete(true)}
                      >
                        Excluir
                      </button>
                    }
                  </div>
                </form>
              </ModalCardbody>
            </ModalCard>
            <ModalDelete openDelete={openDelete} setOpenDelete={closeDelete} mutate={mutate} user={String(changedUser.id)} name={changedUser.nome} />
          </div>
        )}
      </TitleText>
    </Card>
  )
}