import { useContext, useState, useEffect } from 'react'
import { RiImageAddFill } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import ModalImagePreview from '../../../Reports/ModalDetailPrint/ModalImagePreview';
import { UserContext } from '../../../../context/UserContext';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import checklistStyle, { modeloStyleType } from '../../../../service/checklistStyle';
import queryClient from '../../../../service/query';
import AttToken from '../../../../helpers/attToken';

type props = {
  editing: boolean,
  setEditing: Function,
  id: string,
  setId: Function,
  setObjectToEdit: Function,
  objectToEdit: modeloStyleType,
  objectToSend: modeloStyleType,
  setObjectToSend: Function,
  image: string,
  setImage: Function,
  initialObjectToSend: modeloStyleType,
}

export default function StyleConfig({
  editing,
  setEditing,
  setId,
  objectToEdit,
  objectToSend,
  setObjectToSend,
  image,
  setImage,
  initialObjectToSend
}: props) {
  const modelService = new checklistStyle()
  const { openModal } = useContext(UserContext);

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (editing && objectToEdit) {
      setObjectToSend(objectToEdit)
    }
  }, [editing, objectToEdit, setObjectToSend])

  const { mutate: edit, isLoading: isLoadingEdit } = useMutation({
    mutationKey: ['EDIT_MODEL'],
    mutationFn: async () => {
      toast.warn('Editando modelo')
      const token = await AttToken()
      if (token && objectToSend) {
        const response = await modelService.edit(token, objectToSend)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODELS']);
      toast.success('Modelo editado');
      setObjectToSend(initialObjectToSend);
      openModal();
      setId('');
      setImage('');
      setEditing(false);
    }
  })

  const { mutate: create, isLoading } = useMutation({
    mutationKey: ['CREATE_MODEL'],
    mutationFn: async () => {
      toast.warn('Criando novo modelo')
      const token = await AttToken()
      if (token && objectToSend) {
        const response = await modelService.create(token, objectToSend)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODELS'])
      toast.success('Modelo criado')
      setObjectToSend(initialObjectToSend)
      setImage('');
      openModal();
    }
  })

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <>
      {open &&
        <ModalImagePreview
          image={image}
          open={open}
          setOpen={setOpen}
        />}
      <div style={{ display: 'flex', flexDirection: 'column', padding: '30px' }}>
        <label className="checkbox" style={{ paddingBottom: 10 }}>
          <input
            onChange={(event) => setObjectToSend({ ...objectToSend, enablePosMessage: event.target.checked })}
            checked={objectToSend.enablePosMessage}
            type="checkbox"
          />
          &nbsp;<span style={{ fontWeight: 600, color: 'gray' }}
          >Incluir mensagem e foto (opcional) depois da pesquisa
          </span>
        </label>
        {objectToSend.enablePosMessage && <div style={{ backgroundColor: '#E8E8E8', padding: 10 }}>
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            fontWeight: 600,
            fontSize: '13px'
          }}>
            <span>Mensagem:</span><span>(caracteres {objectToSend.messageAfter?.length})</span>
          </div>
          <textarea
            value={objectToSend.messageAfter}
            onChange={(event) => setObjectToSend({ ...objectToSend, messageAfter: event.target.value })}
            maxLength={1000}
            rows={5}
            className='textarea'
            style={{ maxHeight: 80 }} />
          <div style={{ marginTop: 10 }}>
            <span className='button is-success is-small'>
              <input
                accept="image/png, image/jpeg"
                value={undefined}
                id="img"
                name="img"
                multiple={true}
                className="file-input"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    Array.from(event.target.files).map(e => getBase64(e, (i) => setObjectToSend({ ...objectToSend, imageAfter: i })));
                  }
                }}
              />
              <RiImageAddFill />&nbsp; {objectToSend.imageAfter ? 'Alterar foto' : 'Escolher foto'}
            </span>
            {objectToSend.imageAfter &&
              <>
                <button
                  onClick={() => {
                    setImage(objectToSend.imageAfter as string)
                    setOpen(true)
                  }}
                  className='button is-small is-info'
                  style={{ marginLeft: 10 }} >
                  <FaEye />
                </button>
                <button
                  onClick={() => setObjectToSend({ ...objectToSend, imageAfter: '' })}
                  style={{ marginLeft: 10 }}
                  className='button is-small is-danger'>
                  <FaRegTrashCan />
                </button>
              </>
            }

          </div>
        </div>}
        <label className="checkbox" style={{ paddingBottom: 10 }}>
          <input
            onChange={(event) => setObjectToSend({ ...objectToSend, enablePostMessage: event.target.checked })}
            checked={objectToSend.enablePostMessage}
            type="checkbox" />
          &nbsp;<span style={{ fontWeight: 600, color: 'gray' }}>Incluir mensagem e foto (opcional) antes da pesquisa</span>
        </label>
        {objectToSend.enablePostMessage && <div style={{ backgroundColor: '#E8E8E8', padding: 10 }}>
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            fontWeight: 600,
            fontSize: '13px'
          }}>
            <span>Mensagem:</span><span>(caracteres {objectToSend.messageBefore?.length})</span>
          </div>
          <textarea
            value={objectToSend.messageBefore}
            onChange={(event) => setObjectToSend({ ...objectToSend, messageBefore: event.target.value })}
            maxLength={1000}
            rows={5}
            className='textarea'
            style={{ maxHeight: 80 }} />
          <div style={{ marginTop: 10 }}>
            <span className='button is-success is-small'>
              <input
                accept="image/png, image/jpeg"
                value={undefined}
                id="img"
                name="img"
                multiple={true}
                className="file-input"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    Array.from(event.target.files).map(e => getBase64(e, (i) => setObjectToSend({ ...objectToSend, imageBefore: i })));
                  }
                }}
              />
              <RiImageAddFill />&nbsp; {objectToSend.imageBefore ? 'Alterar foto' : 'Escolher foto'}
            </span>
            {objectToSend.imageBefore &&
              <>
                <button
                  onClick={() => {
                    setImage(objectToSend.imageBefore as string)
                    setOpen(true)
                  }}
                  className='button is-small is-info'
                  style={{ marginLeft: 10 }} >
                  <FaEye />
                </button>
                <button
                  onClick={() => setObjectToSend({ ...objectToSend, imageBefore: '' })}
                  style={{ marginLeft: 10 }}
                  className='button is-small is-danger'>
                  <FaRegTrashCan />
                </button>
              </>
            }

          </div>
        </div>}
        <label className="checkbox" style={{ paddingBottom: 10 }}>
          <input
            onChange={(event) => setObjectToSend({ ...objectToSend, enableLoop: event.target.checked, enableRedirect: false })}
            checked={objectToSend.enableLoop}
            type="checkbox" />
          &nbsp;<span style={{ fontWeight: 600, color: 'gray' }}>Habilitar loop na Pesquisa</span>
        </label>
        <label className="checkbox" style={{ paddingBottom: 10 }}>
          <input
            onChange={(event) => setObjectToSend({ ...objectToSend, enableRedirect: event.target.checked, enableLoop: false })}
            checked={objectToSend.enableRedirect}
            type="checkbox" />
          &nbsp;<span style={{ fontWeight: 600, color: 'gray' }}>Habilitar site de redirecionamento</span>
        </label>
        {objectToSend.enableRedirect && <div style={{ backgroundColor: '#E8E8E8', padding: 10 }}>
          <div style={{
            justifyContent: 'space-between',
            fontWeight: 600,
            fontSize: '13px'
          }}>
            <span>URL:</span>
          </div>
          <input
            placeholder='https://www.example.com.br'
            value={objectToSend.link}
            onChange={(event) => setObjectToSend({ ...objectToSend, link: event.target.value })}
            className='input is-small'
          />
        </div>}
      </div>
      <footer className="modal-card-foot" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: 0 }}>
        <button
          className="button is-success is-small"
          onClick={!editing ? () => create() : () => edit()}
          disabled={isLoading || isLoadingEdit || !objectToSend.associatedChecklists?.length}
        >
          Salvar
        </button>
        <button className="button is-small"
          disabled={isLoading || isLoadingEdit}
          onClick={() => {
            setId('')
            openModal();
          }}>Cancelar
        </button>
      </footer>
    </>
  )
}
