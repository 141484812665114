import React, { useMemo, useRef, useState } from 'react'
import { GenericHeader } from '../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import AttToken from '../../helpers/attToken'
import Checklists from '../../service/Checklists'
import FormFilling from './FormFilling'
import { Label } from '../Notifications/style/SingleNotificationStyle';
import Clients, { HierarchyForWebForm, IdNameObj, conjuntosList, locaisList } from '../../service/Clients'
import { toast } from 'react-toastify'
import Select from '../Skeletons/Select'
import { Skeleton } from '@mui/material'
import Anonimo from '../../service/Anonimo'
import CheckMandatory from './CheckMandatorys'

const clientsService = new Clients();
const serviceAnonimo = new Anonimo();

type props = {
  formId: string,
  setFormId: Function,
}

export default function FormFillingModal({ formId, setFormId }: props) {
  const [selectedCliente, setSelectedCliente] = useState<HierarchyForWebForm>();
  const [selectedLocal, setSelectedLocal] = useState<locaisList>();
  const [selectedConjunto, setSelectedConjunto] = useState<conjuntosList>();
  const [selectedUnidade, setSelectedUnidade] = useState<IdNameObj>();

  const checkListService = useMemo(() => new Checklists(), []);

  const INICIO = Date.now();

  const { t } = useTranslation('translation');

  const { data: formData, isLoading } = useQuery({
    queryKey: ['FORM_FOR_FILLING'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await checkListService.getChecklistForWebFilling(token, formId, false)
        return response.formToFilling;
      }
    },
    retry: 5,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { data: hierarchyList, isLoading: isLoadingHierarchy } = useQuery({
    queryKey: ['hierarchyList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response: HierarchyForWebForm[] = await clientsService.getListForWebForm(token, formId);
        return response;
      }
    },
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate: executaLogado } = useMutation({
    mutationKey: ['EXECUTA-ANONIMO'],
    mutationFn: async () => {
      const response = await toast.promise(async () => {
        const token = await AttToken()
        if (token && selectedCliente && selectedLocal && selectedConjunto && selectedUnidade) {
          const hierarquia = {
            cliente: selectedCliente.id,
            local: selectedLocal.id,
            conjunto: selectedConjunto.id,
            unidade: selectedUnidade.id,
          }
          const response = await serviceAnonimo.executaChecklistLogado(token, hierarquia, { ...formData, inicio: INICIO })
          return response
        }
      },{
        success: 'Formulário executado com sucesso',
        pending: 'Executando Formulário...',
        error: 'Falha ao executar o formulário!'
      })
      return response
    },
    onSuccess: () => {
      setFormId('');
    }
  })

  const localRef = useRef<HTMLSelectElement>(null)

  return (
    <div className={`modal ${formId ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setFormId('')} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {`Preencher Formulário`}
          </GenericHeader>
        </header>
        <div className="modal-card-body" style={{ justifyContent: 'space-between' }}>
          <span
            style={{ alignSelf: 'center' }}
          >
            Selecione a Hierarquia geográfica:
          </span><br />
          <span
            style={{
              backgroundColor: !selectedUnidade ? '#F6D265' : '#31AD21',
              padding: '5px',
              borderRadius: '5px',
              margin: '5px 0 5px 0',
              color: !selectedUnidade ? 'black' : 'white'
            }}
          >
            {!selectedUnidade ? 'Para executar o formulário é necessário selecionar todas as opções de hierarquia' : 'Hierarquia Selecionada!'}
          </span>
          <div>
            <Label style={{ alignItems: 'center', width: '65%', justifyContent: 'space-between' }}>
              Cliente:
            {isLoadingHierarchy ? <Select height='35px' width='325px' /> :
              <select
                defaultValue=''
                className='select is-small'
                onChange={(ev) => {
                  const cliente = hierarchyList?.find(cl => cl.id === ev.target.value)
                  setSelectedCliente(cliente);
                  setSelectedLocal(undefined);
                  setSelectedConjunto(undefined);
                  setSelectedUnidade(undefined);
                  if (localRef && localRef.current) {
                    localRef.current.value = ''
                  }
                }}
                style={{ width: '325px' }}
              >
                <option disabled value="">{t('select')}</option>
                {hierarchyList?.sort((a: HierarchyForWebForm, b: HierarchyForWebForm) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  .map((cl: HierarchyForWebForm, i: number) => (
                    <option value={cl.id} key={i}>
                      {cl.nome}
                    </option>
                  ))}
              </select>}
            </Label>
            {selectedCliente ? <Label style={{ alignItems: 'center', width: '65%', justifyContent: 'space-between' }}>
              Local:
              {!selectedCliente ? <Select height='35px' width='325px' /> :
                <select
                  defaultValue=''
                  className='select is-small'
                  ref={localRef}
                  onChange={(ev) => {
                    const place = selectedCliente.locaisList.find(e => e.id === ev.target.value);
                    setSelectedLocal(place);
                    setSelectedConjunto(undefined)
                    setSelectedUnidade(undefined);
                  }}
                  style={{ width: '325px' }}
                >
                  <option disabled value="">{t('select')}</option>
                  {selectedCliente && selectedCliente.locaisList.sort((a: locaisList, b: locaisList) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    .map((place: locaisList, i: number) => (
                      <option value={place.id} key={i}>
                        {place.nome}
                      </option>
                    ))}
                </select>}
            </Label> : <></>}
            {selectedLocal ? <Label style={{ alignItems: 'center', width: '65%', justifyContent: 'space-between' }}>
              Conjunto:
              {!selectedLocal ? <Select height='35px' width='325px' /> : <select
                defaultValue=''
                className='select is-small'
                onChange={(ev) => {
                  const conj = selectedLocal.conjuntosList.find(conj => conj.id === ev.target.value);
                  setSelectedConjunto(conj);
                  setSelectedUnidade(undefined);
                }}
                style={{ width: '325px' }}
              >
                <option disabled value="">{t('select')}</option>
                {selectedLocal.conjuntosList.sort((a: conjuntosList, b: conjuntosList) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  .map((conj, i) => (
                    <option value={conj.id} key={i}>
                      {conj.nome}
                    </option>
                  ))}
              </select>}
            </Label> : <></>}
            {selectedConjunto ? <Label style={{ alignItems: 'center', width: '65%', justifyContent: 'space-between' }}>
              Unidade:
              {!selectedConjunto ? <Select height='35px' width='325px' /> :
                <select
                  defaultValue=''
                  className='select is-small'
                  onChange={(ev) => {
                    const unidade = selectedConjunto.unidadesList.find(uni => uni.id === ev.target.value)
                    setSelectedUnidade(unidade);
                  }}
                  style={{ width: '325px' }}
                >
                  <option disabled value="">{t('select')}</option>
                  {selectedConjunto.unidadesList.sort((a: IdNameObj, b: IdNameObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    .map((uni, i) => (
                      <option value={uni.id} key={i}>
                        {uni.nome}
                      </option>
                    ))}
                </select>}
            </Label> : <></>}
          </div>
          {isLoading ? <Skeleton style={{ height: 500, padding: 0, marginTop: '-100px', marginBottom: '-80px' }} /> :
            <div style={{ width: '100%' }}>
              <FormFilling form={formData} />
            </div>
          }
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            disabled={!selectedUnidade}
            type='button'
            className="button is-success is-small"
            onClick={() => {
              const result = CheckMandatory(formData);
              if (!result.success) {
                if (result.chamado) toast.warn(`Você precisa abrir um chamado no Subitem "${result.subItem}" dentro do item "${result.item}"`)
                else if (result.texto) {
                  toast.warn(result.texto)
                }
                else if (result.item === 'chamado') toast.warn('Você precisa abrir um chamado para finalizar esse formulário!')
                else toast.warn(`Subitem "${result.subItem}" não preenchido dentro do item "${result.item}"`)
              } else {
                executaLogado()
                localStorage.removeItem('inicio')
              }

            }}
          >
            {t('save')}
          </button>
          <button
            type="button"
            onClick={() => setFormId('')}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
