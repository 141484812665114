import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext';
import UserProvider from '../context/UserContext';
import Checklists from '../pages/Checklists';
import Clients from '../pages/Clients';
import CompaniesManage from '../pages/CompaniesManage';
import Login from '../pages/Login';
import Managements from '../pages/Managements';
import Notifications from '../pages/Notifications';
import Places from '../pages/Services';
import Recuperacao from '../pages/Recuperacao';
import Reports from '../pages/Reports';
import Settings from '../pages/Settings';
import Teams from '../pages/Teams';
import Tickets from '../pages/Tickets';
import Users from '../pages/Users';
import { PrivateRoute } from './PrivateRoute';
import Bi from '../pages/Bi';
import Aprovations from '../pages/Aprovations';
import Filling from '../pages/Filling';
import FormFilling from '../components/Filling/FormFilling';
import WebFormProvider from '../context/WebFormContext';
import Home from '../pages/Home';
import LinkExterno from '../components/Reports/ModalDetailPrint/LinkExterno';
import Researches from '../pages/Researches';
import PlanoDeAcao from '../pages/PlanoDeAcao';
import TicketInPath from '../pages/TicketInPath';

export default function Rotas() {
  const loginData = localStorage.getItem('loginData')
  return (
    <Router>
      <UserProvider>
        <WebFormProvider>
          <AuthProvider>
            <Routes>
              <Route path="/home" element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } />
              <Route path="/preenchedor/:formId" element={<FormFilling />} />
              <Route path="/detalhe-checklist/:formId/:user/:timestamp" element={<LinkExterno />} />
              <Route path="/detalhe-checklist/:formId/:user" element={<LinkExterno />} />
              <Route path="/" element={loginData ? <Navigate to="/home" /> : <Navigate to="/login" />} />
              <Route path="/resetPassword/:uid" element={<Recuperacao />} />
              <Route path="/login" element={loginData ? <Navigate to="/home" /> : <Login />} />
              <Route path="/notificacaoWeb" element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              } />
              <Route path="/pesquisas" element={
                <PrivateRoute>
                  <Researches />
                </PrivateRoute>
              } />
              <Route path={`/detalheChamado/:id`} element={
                <PrivateRoute>
                  <TicketInPath />
                </PrivateRoute>
              } />
              <Route path="/plano-de-acao" element={
                <PrivateRoute>
                  <PlanoDeAcao />
                </PrivateRoute>
              } />
              <Route path="/configuracoes" element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              } />
              <Route path="/aprovacao" element={
                <PrivateRoute>
                  <Aprovations />
                </PrivateRoute>
              } />
              <Route path="/usuarios" element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              } />
              <Route path="/locais" element={
                <PrivateRoute>
                  <Places />
                </PrivateRoute>
              } />
              <Route path="/formularios" element={
                <PrivateRoute>
                  <Checklists />
                </PrivateRoute>
              } />
              <Route path="/clientes" element={
                <PrivateRoute>
                  <Clients />
                </PrivateRoute>
              } />
              <Route path="/equipes" element={
                <PrivateRoute>
                  <Teams />
                </PrivateRoute>
              } />
              <Route path="/relatorios" element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              } />
              <Route path="/gerencias" element={
                <PrivateRoute>
                  <Managements />
                </PrivateRoute>
              } />
              <Route path="/notificacoes" element={
                <PrivateRoute>
                  <Tickets />
                </PrivateRoute>
              } />

              <Route path="/empresas" element={
                <PrivateRoute>
                  <CompaniesManage />
                </PrivateRoute>
              } />
              <Route path="/powerbi" element={
                <PrivateRoute>
                  <Bi />
                </PrivateRoute>
              } />
              <Route path="/preenchimento" element={
                <PrivateRoute>
                  <Filling />
                </PrivateRoute>
              } />
            </Routes>
          </AuthProvider>
        </WebFormProvider>
      </UserProvider>
    </Router>
  );
}