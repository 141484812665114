import { useTranslation } from 'react-i18next';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import { useEffect, useMemo, useState } from 'react';
import ServiceTypes, { types } from '../../../service/ServiceTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import Clients, { ClientsWithChildren } from '../../../service/Clients';
import SetTicketAcordion from './SetTicketAcordion';
import { Autocomplete, Pagination, Skeleton } from '@mui/material';
import NovoChamado from '../../../service/NovoChamado';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import Users from '../../../service/Users';
import { AxiosError } from 'axios';
import { userBackObj } from '../../Users/UserSearch';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export type newTicketObjType = {
  id?: string,
  name: string,
  message: string,
  serviceType: string,
  daysLimit?: number | null,
  timeLimit?: number | null,
  setsList: string[],
  responsible: string,
  redirectable?: boolean
}

type props = {
  isEdit?: boolean,
  setIsEdit: Function,
  ticketEdit?: newTicketObjType | undefined,
  setTicketEdit: Function,
  openModal: boolean,
  setOpenModal: Function,
}

const serviceType = new ServiceTypes();
const CustomerService = new Clients();
const chamadoService = new NovoChamado();
const usersService = new Users();

export default function NewTicketModal({ isEdit, setIsEdit, ticketEdit, setTicketEdit, openModal, setOpenModal }: props) {
  const { t } = useTranslation('translation');
  const [children, setChildren] = useState<ClientsWithChildren[] | undefined>(undefined);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<any[]>([]);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (ticketEdit?.serviceType) {
      const getServices = async () => {
        const token = await AttToken()
        if (token) {
          const response = await CustomerService.getAllWithSets(token, ticketEdit?.serviceType as string);
          return setChildren(response)
        }
      }
      getServices()
    }
  }, [ticketEdit, CustomerService])

  const { data: servicesData, isLoading: isLoadingSevices } = useQuery({
    queryKey: ['GET_SERVICES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: childrenData, mutate } = useMutation({
    mutationKey: ['ClientesChildren'],
    mutationFn: async (value: string) => {
      const token = await AttToken();
      if (token) {
        const response = await CustomerService.getAllWithSets(token, value);
        return response
      }
    },
  });

  const { mutate: createNewTicket } = useMutation({
    mutationKey: ['newTicketModel'],
    mutationFn: async () => {
      toast.warn(`${t('notifications.ticket.CriandoNovoModelo')}`)
      const token = await AttToken()
      if (token && ticketEdit) {
        const response = await chamadoService.createModel(token, ticketEdit)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODEL_LIST'])
      setTicketEdit(undefined)
      setOpenModal(!openModal)
      toast.success(`${t('notifications.ticket.ModeloCriado')}`)
    },
    onError: () => {

      toast.error(`${t('notifications.ticket.ErroAoCriarModelo')}`)
    }
  })

  const { mutate: updateTicket } = useMutation({
    mutationKey: ['updateTicketModel'],
    mutationFn: async () => {
      toast.warn(`${t('notifications.ticket.AtualizandoModelo')}`)
      const token = await AttToken()
      if (token && ticketEdit) {
        const response = await chamadoService.updateModel(token, ticketEdit)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODEL_LIST'])
      setTicketEdit(undefined)
      setOpenModal(!openModal)
      toast.success(`${t('notifications.ticket.ModeloAlterado')}`)
    },
    onError: () => {
      toast.error(`${t('notifications.ticket.ErroAoAlterar')}`)
    }
  })

  const {
    data,
    isLoading
  } = useQuery({
    queryKey: ['GET_USERS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (childrenData) {
      const currentPageData = childrenData?.sort((a: any, b: any) => { return a.nome > b.nome ? 1 : -1 })
        ?.filter((e: any) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    } else if (children) {
      const currentPageData = children?.sort((a: any, b: any) => { return a.nome > b.nome ? 1 : -1 })
        ?.filter((e: any) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, childrenData, children, search])

  let pages = [];

  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  return (
    <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>{!isEdit ? t('notifications.ticket.criarModelo') : t('notifications.ticket.editarModelo')}</GenericHeader>
        </header>
        <section className="modal-card-body" style={{ textAlign: 'initial' }}>
          <TextField
            style={{ marginBottom: '10px' }}
            fullWidth
            value={ticketEdit?.name}
            label={t('name')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTicketEdit({ ...ticketEdit, name: e.target.value })}
          />
          <TextField
            style={{ marginBottom: '10px' }}
            fullWidth
            multiline
            rows={2}
            maxRows={4}
            value={ticketEdit?.message}
            label={t('message')}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTicketEdit({ ...ticketEdit, message: e.target.value })}
          />
          <div style={{ display: 'flex' }}>
            <Label>{t('notifications.ticket.redirectable')}</Label>&nbsp;
            <input
              type="checkbox"
              checked={ticketEdit?.redirectable}
              placeholder={t('notifications.ticket.redirectable')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTicketEdit({ ...ticketEdit, redirectable: e.target.checked })}
            />
          </div>
          {isLoading ? <Skeleton /> :
            <Autocomplete
              value={ticketEdit?.responsible ? {
                value: ticketEdit?.responsible as string,
                label: data?.find((ele: userBackObj) => ele.id === ticketEdit?.responsible)?.nome as string
              } : undefined}
              //isOptionEqualToValue={(option, value) => option.value === value.value}
              filterSelectedOptions
              options={
                data
                  ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.filter((e: userBackObj) => e.status === 'ativo')
                  ?.filter((el: userBackObj) => el.aprovador === true)
                  ?.map((ele: any) => ({
                    label: ele.nome,
                    value: ele.id,
                  }))}
              onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                if (newValue) {
                  setTicketEdit({ ...ticketEdit, responsible: newValue?.value })
                } else setTicketEdit({ ...ticketEdit, responsible: null })
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={t('notifications.ticket.UsuárioResponsávelConclusao')} />}
            />
          }
          <div className='columns' style={{ marginTop: '5px', display: 'flex', alignItems: 'center' }}>
            {isLoadingSevices ? <Skeleton sx={{ marginLeft: "5px" }} variant='rounded' width={'45%'} /> :
              <Autocomplete
                id="combo-box-demo"
                disabled={ticketEdit?.serviceType !== ''}
                className='column'
                value={ticketEdit?.serviceType ? {
                  value: ticketEdit?.serviceType as string,
                  label: servicesData?.find((ele: types) => ele.tipoId === ticketEdit?.serviceType).nome
                } : null}
                style={{ fontSize: '10px !important' }}
                filterSelectedOptions
                options={
                  servicesData?.sort((a: types, b: types) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })?.map((ele: any) => ({
                    label: ele.nome,
                    value: ele.tipoId,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
                  setTicketEdit({ ...ticketEdit, serviceType: details.option.value })
                  mutate(details.option.value)
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={t('notifications.ticket.tipoServico')} />}
              />
            }
            <div className='column'>
              {ticketEdit?.serviceType !== "" &&
                <button
                  onClick={() => {
                    setTicketEdit({ ...ticketEdit, serviceType: "", setsList: [] });
                  }}
                  className='button is-danger is-small'>
                  {t('formDetails.Association.desassociar')}
                </button>}
            </div>
          </div>
          {ticketEdit && ticketEdit?.serviceType?.length > 0 && <Accordion style={{ marginBottom: '15px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              Clientes
            </AccordionSummary>
            {children ?
              <>
                {(childrenData?.length > 5 || children.length > 5) &&
                  <>
                    <Pagination
                      style={{ display: `${!pages?.length ? 'none' : ''}` }}
                      showFirstButton
                      showLastButton
                      shape="rounded"
                      variant="outlined"
                      count={pages?.length}
                      defaultPage={currentPage}
                      onChange={(eve, pageNumber) => {
                        setCurrentPage(pageNumber);

                      }}
                    />
                    <div style={{padding: '0 10px 0 10px', marginBottom: '10px'}}>
                    <TextField
                      fullWidth
                      value={search}
                      onChange={(event) => setSearch(event.target.value.toLowerCase())}
                      style={{ marginBottom: '10px' }}
                      label='Pesquisar'
                    />
                    </div>
                  </>
                }
                {ticketEdit?.serviceType.length &&
                  !paginated ?
                  <Skeleton className="input is-large" style={{ marginTop: 0 }} /> : ticketEdit?.serviceType !== '' &&
                    paginated?.length > 0 ?
                    paginated?.map((e: ClientsWithChildren) => (
                      <SetTicketAcordion expanded={expanded} handleChange={handleChange} key={e.id} client={e} ticket={ticketEdit as newTicketObjType} setTicketObj={setTicketEdit} />
                    )) : ticketEdit?.serviceType !== '' && <div style={{ textAlign: 'center' }}>Não há locais associados a este tipo de serviço</div>
                }
              </>
              :
              <>
                {ticketEdit?.serviceType !== '' &&
                  !paginated ?
                  <Skeleton className="input is-large" style={{ marginTop: 0 }} /> : ticketEdit?.serviceType !== '' &&
                    paginated?.length > 0 ?
                    paginated?.map((e: ClientsWithChildren) => (
                      <SetTicketAcordion expanded={expanded} handleChange={handleChange} client={e} ticket={ticketEdit as newTicketObjType} setTicketObj={setTicketEdit} />
                    )) : ticketEdit?.serviceType !== '' && <div style={{ textAlign: 'center' }}>Não há locais associados a este tipo de serviço</div>
                }
              </>
            }
          </Accordion>}
          <div className='columns'>
            <div className='column'>
              <TextField
                fullWidth
                type='number'
                onChange={(event) => setTicketEdit({ ...ticketEdit, daysLimit: Number(event.target.value) })}
                value={ticketEdit?.daysLimit as number}
                label={'Dias'}
              />
            </div>
            <div className='column'>
              <TextField
                fullWidth
                type='number'
                onChange={(event) => setTicketEdit({ ...ticketEdit, timeLimit: Number(event.target.value) })}
                value={ticketEdit?.timeLimit as number}
                label={t('hour')}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            disabled={ticketEdit?.name === '' ||
              ticketEdit?.message === '' ||
              ticketEdit?.serviceType === '' ||
              !ticketEdit?.setsList?.length}
            className="button is-success is-small"
            onClick={() => {
              isEdit ? updateTicket() :
                createNewTicket()
            }}>{`${t('save')}`}</button>
          <button
            className="button is-warning is-small"
            onClick={() => {
              setOpenModal(!openModal)
              setIsEdit(false)
              setChildren(undefined)
            }}>{`${t('cancel')}`}</button>
        </footer>
      </div>
    </div >
  )
}
