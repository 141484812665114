import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";
import { ChecklistReportType } from "./Reports";

export type TicketsType = {
  checklistId: number;
  itemId: string;
  cliente: string;
  conjunto: string;
  dataMudancaStatus: number;
  dataPrevisao: number;
  dataPrevisaoFormatada: string;
  empresa: string;
  expirado: Boolean;
  gerencia: string;
  grupoNotificacao: number;
  id: string;
  inicio: number;
  inicioFormatado: string;
  instrucao: string;
  local: string;
  nome: string;
  nomeCliente: string;
  nomeConjunto: string;
  nomeGerencia: string;
  nomeLocal: string;
  nomeUnidade: string;
  status: string;
  statusClass: string;
  statusFormatado: string;
  unidade: string;
  userMudancaStatus: string;
  usuario: string;
  usuarioNome: string;
  subitemId: number;
  execucaoChecklist: ChecklistReportType;
  motivoEncaminhamento: string;
  historicoChamado?: {
    [key: string]: {
      comentario: string;
      data: number;
      dataFormatada: string;
      dataMensagem: number;
      dataMensagemFormatada: string;
      id: string;
      idChamado: string;
      nomeUsuarioAcompanhamento: string;
      status: string;
      statusClass: string;
      statusTraduzido: string;
      usuarioAcompanhamento: string;
      foto: string;
    };
  };
  encaminha: boolean;
  block?: boolean;
  responsavel?: string;
  responsavelNome?: string;
};

export type novaMensagem = {
  comentario: string;
  dataAcompanhamento: number | Date;
  status: string;
  referenciaFoto: string | null;
};

export type filtrosType = {
  userEmissor: string[] | null,//(array de id de usuario),
  expirado: boolean | null,
  modelos: number[] | null //(array de id de modelos de chamado antigo),
  modelosNovos: string[] | null, //(array de id de modelos de chamado2.0),
  inicioRange: {
    de: number | null,
    ate: number | null,
  } | null,
  previsaoRange: {
    de: number | null,
    ate: number | null
  } | null,
  status: string[] | null,
  cliente: string[] | null, //(nome do cliente),
  local: string[] | null, //(nome do local),
  conjunto: string[] | null, //(nome do conjunto),
  unidade: string[] | null, //(nome da unidade),//(array de status possiveis de chamado)
  ordenarPor: string | null,
  ordem: boolean | null,
  responsavel: string[] | null
} | null

class Tickets {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/chamados`,
  });

  getAll = async (body: AuthBodyType, itensPerPage: number, pageNumber: number, mobileFilter: filtrosType | null, emitidos: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAll", {
        ...body,
        itensPerPage,
        pageNumber,
        mobileFilter,
        emitidos,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getAllGestor = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getGeneralList", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  update = async (
    body: AuthBodyType,
    chamado: string | boolean, //id do chamado
    novaMensagem: {
      novaMensagem: {
        comentario?: string;
        dataAcompanhamento: number | Date;
        status: string;
        referenciaFoto: string | null;
      };
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/updateChamado", {
        ...body,
        chamado,
        ...novaMensagem,
      });
      return data.data.objChamados;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getTicketById = async (
    body: AuthBodyType,
    chamadoId: string | boolean //id do chamado
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(`/getChamado`, {
        ...body,
        chamadoId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  redirecionaChamado = async (
    body: AuthBodyType,
    chamadoId: string | boolean, //id do chamado
    motivoEncaminhamento: string | null,
    encaminhadoPara: string | null,
    action: "redireciona" | "aceita" | "rejeita"
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(`/redirecionaChamado`, {
        ...body,
        chamadoId,
        motivoEncaminhamento,
        encaminhadoPara,
        action,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getFiltersForWeb = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post(`/getFiltersForWeb`, {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default Tickets;
