import { Card, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../context/UserContext';
import { Label } from '../../../StyledComponents/Input/generic';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import { NewPlaceWrapper } from './ModalStyle';

export default function NewModal({
  setModalOpen,
  modalOpen,
  create,
  nameCreate,
  isDisabled
}
  : {
    setModalOpen: Function,
    modalOpen: boolean,
    create: Function,
    nameCreate: string
    isDisabled: boolean
  }) {
  const { openModal, escape } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const { hierarchyLabel } = useContext(UserContext);
  const [name, setName] = useState('');

  return (
    <Card>
      <TitleText>
        <div className={`modal ${modalOpen ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                Adicionar {nameCreate === 'conjunto' ? hierarchyLabel.conjunto : hierarchyLabel.unidade}
              </GenericHeader>
            </header>
            <div className="modal-card-body">
                <TextField
                  style={{marginBottom:'20px'}}
                  fullWidth
                  label={t('name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button disabled={name === '' || isDisabled} className='button is-success is-small' onClick={() => create(name)}>{t('save')}</button>
              <button className='button is-warning is-small' onClick={() => setModalOpen(false)}>{t('cancel')}</button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
