import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ChecklistSubitem, ChecklistType, ItemChecklist, TipoSelecaoType } from '../../../service/Checklists';
import { Input } from '../../../StyledComponents/Input/generic';
import { GenericHeader, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic'
import { Card, TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { WeightInput } from '../FormStyle';
import { BoxContainer, ButtonsContainer, HorizontalLine } from './FormModal/FormModalStyle';
import SubItemTypeComponent from './FormModal/SubItemTypeComponent';
import FormSubItemHelpModal from './FormSubItemHelpModal';
import { UserContext, identifierTypesList } from '../../../context/UserContext';
import { TextField } from '@material-ui/core';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const key = 'checklists.subitemModal.'
const questionKey = 'checklists.subitemModal.questionTypes.';

type avalicacaoType = {
  nome: string,
  valor: number,
}

export default function FormSubitemModal({
  fatherIndex,
  thisIndex,
  subItem,
  setEditSubModalOpen,
  editSubModalOpen,
  isEditing,
  setEditingForm,
  form,
  item,
  setSubModalOpen
}:
  {
    fatherIndex: number,
    thisIndex: number,
    subItem: ChecklistSubitem,
    form: ChecklistType,
    setEditSubModalOpen: Function,
    editSubModalOpen: boolean,
    isEditing: boolean,
    setEditingForm: Function,
    item: ItemChecklist,
    setSubModalOpen: Function
  }) {
  const { t } = useTranslation('translation');
  const [helpModal, setHelpModdal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [subItemType, setSubItemType] = useState(subItem.tipo || 'avaliacao');
  const optionsSelectMap = {
    avaliacao: t(questionKey + 'avaliation'), multiple: t(questionKey + 'multiple'),
    unique: t(questionKey + 'unique'),
    textoCurto: t(questionKey + 'shortText'), textoLongo: t(questionKey + 'longText'),
    list: t(questionKey + 'textList'),
    data: t(questionKey + 'date'), hora: t(questionKey + 'hour'),
    numero: t(questionKey + 'number'), assinatura: t(questionKey + 'signature'),
    email: 'e-mail', telefone: t(questionKey + 'tel'),
    rg: 'RG', cpfcnpj: 'CPF/CNPJ',
    cep: 'CEP', placa: t(questionKey + 'placa')
  }
  const [nome, setNome] = useState(subItem.nome);
  const [preenchimento, setPreenchimento] = useState(subItem.obrigatoriedade?.preenchimento || false);
  const [foto, setFoto] = useState(subItem.foto || false);
  const [comentario, setComentario] = useState(subItem.comentario || false);
  const [peso, setPeso] = useState<number | undefined>(subItem.peso ? Number(subItem.peso) : 0)
  const [conformeOcorrencia, setConformeOcorrencia] = useState(false);
  const [conformeImg, setConformeImg] = useState(false);
  const [naoConformeOcorrencia, setNaoConformeOcorrencia] = useState(false);
  const [naoConformeImg, setNaoConformeImg] = useState(false);
  const [naoAplicaOcorrencia, setNaoAplicaOcorrencia] = useState(false);
  const [naoAplicaImg, setNaoAplicaImg] = useState(false);
  const [preenchImg, setPreenchImg] = useState(subItem.obrigatoriedade?.img || false)
  const [preenchObs, setPreenchObs] = useState(subItem.obrigatoriedade?.ocorrencia || false)
  const [galeriaFoto, setGaleriaFoto] = useState(subItem.galeriaFoto || false);
  const [naoAplica, setNaoAplica] = useState(subItem?.avaliacao?.find((e: avalicacaoType) => e.valor === -1) ? true : false);
  const [avaliacao, setAvaliacao] = useState(subItem.avaliacao ? [...subItem.avaliacao] : null);
  const [threshold, setThreshold] = useState(subItem.threshold);
  const [padrao, setPadrao] = useState<any>(subItem.padrao);
  const [selecao, setSelecao] = useState(subItem.selecao);
  const [padraoLista, setPadraoLista] = useState(subItem.padraoLista);
  const [maxNumberVal, setMaxNumberVal] = useState<number | null>(subItem.maxNumberVal as number);
  const [minNumberVal, setMinNumberVal] = useState<number | null>(subItem.minNumberVal as number);
  const [help, setHelp] = useState(subItem.help);
  const [disable, setDisable] = useState(false)
  const [hideImg, setHideImg] = useState<boolean>(!subItem?.hideImg ? true : false);
  const [hideComment, setHideComment] = useState<boolean>(!subItem?.hideComent ? true : false);
  const [tipoSelecao, setTipoSelecao] = useState<TipoSelecaoType>(subItem.tipoSelecao || 'default');
  const [abrePlano, setAbrePlano] = useState(subItem.abrePlano || false)

  const { hierarchyLabel, identifiersByForm, setIdentifiersByForm, acessoPlanoDeAcao } = useContext(UserContext);

  const { trackEvent } = useMatomo()

  const mapTiposSelecao = {
    default: 'Padrão',
    user: 'Usuarios',
    cliente: hierarchyLabel.cliente,
    local: hierarchyLabel.local,
    conjunto: hierarchyLabel.conjunto,
    unidade: hierarchyLabel.unidade
  }

  const escape = useCallback((e: any): void => {
    trackEvent({
      category: 'sample-page',
      action: 'click-event',
      name: `fecha modal`,
    })
    if (e.key === 'Escape') {
      setEditSubModalOpen(false);
    }
  }, [setEditSubModalOpen])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
    subItem.obrigatoriedade?.conforme?.ocorrencia && setConformeOcorrencia(subItem.obrigatoriedade.conforme.ocorrencia);
    subItem.obrigatoriedade?.conforme?.img && setConformeImg(subItem.obrigatoriedade.conforme.img);
    subItem.obrigatoriedade?.naoConforme?.ocorrencia && setNaoConformeOcorrencia(subItem.obrigatoriedade.naoConforme.ocorrencia);
    subItem.obrigatoriedade?.naoConforme?.img && setNaoConformeImg(subItem.obrigatoriedade.naoConforme.img);
    subItem.obrigatoriedade?.NA?.ocorrencia && setNaoAplicaOcorrencia(subItem.obrigatoriedade.NA.ocorrencia);
    subItem.obrigatoriedade?.NA?.img && setNaoAplicaImg(subItem.obrigatoriedade.NA.img);
    subItem.minNumberVal && setMinNumberVal(subItem.minNumberVal)
    subItem.maxNumberVal && setMaxNumberVal(subItem.maxNumberVal)
    subItem.obrigatoriedade?.img && setPreenchImg(subItem.obrigatoriedade?.img)
    subItem.obrigatoriedade?.ocorrencia && setPreenchObs(subItem.obrigatoriedade?.ocorrencia)
    subItem.peso && setPeso(subItem.peso)
  }, [
    escape,
    subItem.obrigatoriedade?.conforme?.ocorrencia,
    subItem.obrigatoriedade?.conforme?.img,
    subItem.obrigatoriedade?.naoConforme?.ocorrencia,
    subItem.obrigatoriedade?.naoConforme?.img,
    subItem.minNumberVal,
    subItem.maxNumberVal,
    subItem.obrigatoriedade?.NA?.ocorrencia,
    subItem.obrigatoriedade?.NA?.img,
    subItem.obrigatoriedade?.img,
    subItem.obrigatoriedade?.ocorrencia,
    subItem.peso
  ])

  const handleSaveBtn = () => {
    trackEvent({
      category: 'sample-page',
      action: 'click-event',
      name: `salva alterações`,
    })
    const obj: ChecklistSubitem = {
      id: subItem.id,
      nome,
      itemid: item.id,
      avaliacao: subItemType === 'avaliacao' ? avaliacao : null,
      comentario,
      hideImg: hideImg ? false : true,
      hideComent: hideComment ? false : true,
      foto,
      galeriaFoto,
      minNumberVal: subItemType === 'numero' ? minNumberVal : null,
      maxNumberVal: subItemType === 'numero' ? maxNumberVal : null,
      obrigatoriedade: {
        img: preenchImg,
        ocorrencia: preenchObs,
        preenchimento,
        conforme: {
          img: conformeImg,
          ocorrencia: conformeOcorrencia
        },
        naoConforme: {
          img: naoConformeImg,
          ocorrencia: naoConformeOcorrencia
        },
        NA: naoAplica ? {
          img: naoAplicaImg || null,
          ocorrencia: naoAplicaOcorrencia || null
        } : null
      },
      padrao,
      padraoLista: subItemType === 'list' ? padraoLista : null,
      peso: subItemType === 'avaliacao' ? peso : null,
      selecionados: subItem.selecionados ? subItem.selecao && subItem.padrao && (subItem?.selecao?.map(el => { return padrao.includes(el) ? true : false }) || Array(subItem.selecao?.length).fill(false)) : null,
      selecao,
      threshold,
      tipo: subItemType,
      help,
      tipoSelecao,
      abrePlano,
    }
    const subIdentifi = `${form.id}|${form.versao}|${fatherIndex}|${thisIndex}`
    const thisIdentifierList = identifiersByForm.find(e => e.formId === form.id)?.identifiersList;
    if (identifierTypesList.includes(subItemType)) {
      if (!thisIdentifierList?.some(e => e.value === subIdentifi)) {
        identifiersByForm.forEach(e => {
          if (e.formId === form.id) {
            e.identifiersList.push({ value: subIdentifi, nome })
          }
        })
      } else {
        identifiersByForm.forEach(e => {
          if (e.formId === form.id) {
            e.identifiersList.forEach(iden => {
              if (iden.value === subIdentifi) {
                iden.nome = nome;
              }
            })
          }
        })
      }
      setIdentifiersByForm([...identifiersByForm])
    } else if (thisIdentifierList?.some(e => e.value === subIdentifi)) {
      identifiersByForm.forEach(e => {
        if (e.formId === form.id) {
          e.identifiersList = e.identifiersList.filter(iden => iden.value !== subIdentifi)
        }
      })
      setIdentifiersByForm([...identifiersByForm])
    }
    form.itens[fatherIndex].subitens[thisIndex] = { ...obj }
    setEditingForm({ ...form })
    setIsHidden(true);
    setEditSubModalOpen(false);
    setSubModalOpen(false);
  }

  const WeightInputChange = () => {
    const InputWeightRef = useRef<HTMLInputElement>(null);
    const [pesoState, setPesoState] = useState<number | string>(typeof peso === 'number' && peso >= 0 ? peso : 1);

    return (
      <>
        <WeightInput
          onChange={(e) => {
            trackEvent({
              category: 'sample-page',
              action: 'typ-event',
              name: `edita peso`,
            })
            setPesoState(e.target.value)
          }}
          onBlur={() => {
            if (InputWeightRef && InputWeightRef.current) {
              const value = InputWeightRef.current.value;
              if (isNaN(Number(value)) || Number(value) < 0) {
                setPeso(1);
                setPesoState(1);
              } else {
                setPeso(Number(value));
                setPesoState(Number(value));
              }
            }
          }}
          min={0}
          value={pesoState}
          disabled={!isEditing}
          ref={InputWeightRef}
        />
      </>
    );
  }

  useEffect(() => {
    if (subItem.tipo === 'avaliacao') {
      localStorage.setItem('avaliacaoBackup', JSON.stringify(subItem.avaliacao));
    } else if (subItem.tipo === 'multiple' || subItem.tipo === 'unique') {
      localStorage.setItem('selecaoBackup', JSON.stringify(subItem.selecao));
    }
  }, [subItem.avaliacao, subItem.selecao, subItem.tipo])

  const checkDisable = () => {
    if (!isEditing) {
      return true
    }
    switch (subItemType) {
      case 'numero':
        if (maxNumberVal && minNumberVal) {
          if ((Number(padrao) > Number(maxNumberVal)) || ((Number(padrao) < Number(minNumberVal)))) {
            return true
          }
        }
        break;
      case 'email':
        const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (padrao?.length > 1 && !regexEmail.test(padrao)) {
          return true;
        }
        break;
      case 'telefone':
        if (padrao?.length && padrao?.length <= 11) {
          return true;
        }
        break;
      case "cpfcnpj":
        if (padrao?.length && (padrao?.length !== 14 && padrao?.length !== 18))
          return true;
        break;
      case "cep":
        if (padrao?.length && padrao?.length !== 9)
          return true;
        break;
      case "placa":
        if (padrao?.length && padrao?.length !== 8)
          return true;
        break;
      default:
        return false
    }
    return false
  }

  const FotoAvaliaCheckBox = () => {
    return (
      <label className='checkbox'>
        <input disabled={!isEditing} type="checkbox"
          checked={hideImg}
          onChange={() => {
            trackEvent({
              category: 'sample-page',
              action: 'click-event',
              name: `habilita/desabilita imagem`,
            })
            setHideImg(!hideImg)
          }}
        />
        {t(key + 'allowPhoto')}
      </label>
    )
  }

  const ComentAvaliaCheckBox = () => {
    return (
      <label className='checkbox'>
        <input disabled={!isEditing} type="checkbox"
          checked={hideComment}
          onChange={() => {
            trackEvent({
              category: 'sample-page',
              action: 'click-event',
              name: `habilita/desabilita comentario`,
            })
            setHideComment(!hideComment)
          }}
        />
        {t(key + 'allowComment')}
      </label>
    )
  }

  const FotoCheckBox = () => {
    return (
      <>
        <label className='checkbox'>
          <input type="checkbox"
            disabled={!isEditing}
            checked={foto}
            onChange={() => {
              trackEvent({
                category: 'sample-page',
                action: 'click-event',
                name: `habilita/desabilita imagem`,
              })
              setFoto(!foto)
            }}
          />
          {t(key + 'allowPhoto')}
        </label>
        <label className='checkbox'>
          <input type="checkbox"
            id='teste'
            checked={preenchImg}
            disabled={!foto || !isEditing}
            onChange={() => {
              trackEvent({
                category: 'sample-page',
                action: 'click-event',
                name: `habilita/desabilita imagem obrigatoria`,
              })
              setPreenchImg(!preenchImg)
            }}
          />
          {t(key + 'mandatoryPhoto')}
        </label>
      </>
    )
  }

  const ComentCheckBox = () => {
    return (
      <>
        <label className='checkbox'>
          <input type="checkbox"
            disabled={!isEditing}
            checked={comentario}
            onChange={() => {
              trackEvent({
                category: 'sample-page',
                action: 'click-event',
                name: `habilita/desabilita comentario`,
              })
              setComentario(!comentario)
            }}
          />
          {t(key + 'allowComment')}
        </label>
        <label className='checkbox'>
          <input type="checkbox"
            checked={preenchObs}
            disabled={!comentario || !isEditing}
            onChange={() => {
              setPreenchObs(!preenchObs)
              trackEvent({
                category: 'sample-page',
                action: 'click-event',
                name: `habilita/desabilita comentario obrigatorio`,
              })
            }}
          />
          {t(key + 'mandatoryComment')}
        </label>
      </>
    )
  }

  const GaleriaCheckBox = () => {
    return (
      <label className='checkbox'>
        <input type="checkbox"
          checked={galeriaFoto}
          disabled={!foto}
          onChange={() => {
            trackEvent({
              category: 'sample-page',
              action: 'click-event',
              name: `habilita/desabilita galeria`,
            })
            setGaleriaFoto(!galeriaFoto)
          }}
        />
        {t(key + 'allowGalery')}
      </label>
    )
  }

  const switchTypes = (tipo: string) => {
    switch (tipo) {
      case 'avaliacao':
        return (
          <>
            <HorizontalLine />
            <BoxContainer>
              {acessoPlanoDeAcao && <label className='checkbox'>
                <input disabled={!isEditing} type="checkbox"
                  checked={abrePlano}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita plano de ação`,
                    })
                    setAbrePlano(!abrePlano)
                  }}
                />
                Permitir abertura de plano de ação
              </label>}
              <FotoAvaliaCheckBox />
              <ComentAvaliaCheckBox />
              <label style={{ marginBottom: '20px', marginTop: '20px' }}>
                {t(key + 'subitemWeight')}
                <WeightInputChange />
              </label>
            </BoxContainer>
            <HorizontalLine />
            <BoxContainer>
              <h1>{t(key + 'notAccording')}</h1>
              <label className='checkbox'>
                <input type="checkbox"
                  checked={naoConformeImg}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita imagem n/conforme`,
                    })
                    setNaoConformeImg(!naoConformeImg)
                  }}
                  disabled={!hideImg || !isEditing}
                />
                {t(key + 'mandatoryPhoto')}
              </label>
              <label className='checkbox'>
                <input type="checkbox"
                  checked={naoConformeOcorrencia}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita comentario n/conforme`,
                    })
                    setNaoConformeOcorrencia(!naoConformeOcorrencia)
                  }}
                  disabled={!hideComment || !isEditing}
                />
                {t(key + 'mandatoryComment')}
              </label>
              <h1>Conforme</h1>
              <label className='checkbox'>
                <input type="checkbox"
                  checked={conformeImg}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita imagem conforme`,
                    })
                    setConformeImg(!conformeImg)
                  }}
                  disabled={!hideImg || !isEditing}
                />
                {t(key + 'mandatoryPhoto')}
              </label>
              <label className='checkbox'>
                <input type="checkbox"
                  checked={conformeOcorrencia}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita comentario conforme`,
                    })
                    setConformeOcorrencia(!conformeOcorrencia)
                  }}
                  disabled={!hideComment || !isEditing}
                />
                {t(key + 'mandatoryComment')}
              </label>
            </BoxContainer>
            {naoAplica && (
              <>
                <HorizontalLine />
                <BoxContainer>
                  <h1>{t('checklists.printConfig.Nao_se_aplica')}</h1>
                  <label className='checkbox'>
                    <input type="checkbox"
                      checked={naoAplicaImg}
                      onChange={() => {
                        trackEvent({
                          category: 'sample-page',
                          action: 'click-event',
                          name: `habilita/desabilita imagem n/aplica`,
                        })
                        setNaoAplicaImg(!naoAplicaImg)
                      }}
                      disabled={!hideImg || !isEditing}
                    />
                    {t(key + 'mandatoryPhoto')}
                  </label>
                  <label className='checkbox'>
                    <input type="checkbox"
                      checked={naoAplicaOcorrencia}
                      onChange={() => {
                        trackEvent({
                          category: 'sample-page',
                          action: 'click-event',
                          name: `habilita/desabilita comentario n/conforme`,
                        })
                        setNaoAplicaOcorrencia(!naoAplicaOcorrencia)
                      }}
                      disabled={!hideComment || !isEditing}
                    />
                    {t(key + 'mandatoryComment')}
                  </label>
                </BoxContainer>
              </>
            )}
            <HorizontalLine />
            <BoxContainer>
              {!form.preenchimentoWeb && <label className='checkbox'>
                <input type="checkbox"
                  disabled={!hideImg || !isEditing}
                  checked={galeriaFoto}
                  onChange={() => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita galeria`,
                    })
                    setGaleriaFoto(!galeriaFoto)
                  }}
                />
                {t(key + 'allowGalery')}
              </label>}
              <label className='checkbox'>
                <input type="checkbox"
                  disabled={!isEditing}
                  checked={naoAplica}
                  onChange={(e) => {
                    trackEvent({
                      category: 'sample-page',
                      action: 'click-event',
                      name: `habilita/desabilita n/aplica`,
                    })
                    setNaoAplica(!naoAplica)
                    if (e.target.checked) setAvaliacao((prev) => prev ? [...prev, { NA: true, nome: 'Não se Aplica', valor: -1 }] : [{ NA: true, nome: 'Não se Aplica', valor: -1 }])
                    else setAvaliacao((prev) => prev && prev.filter(ele => ele.valor >= 0))
                  }}
                />
                {t(key + 'fieldNotApply')}
              </label>
            </BoxContainer>
          </>
        )
      case 'multiple': case 'unique': case 'list': case 'numero': case 'assinatura':
        return (
          <BoxContainer>
            <ComentCheckBox />
            <FotoCheckBox />
            {!form.preenchimentoWeb && <GaleriaCheckBox />}
          </BoxContainer>
        )
      case 'textoCurto': case 'textoLongo': case 'email': case 'cpfcnpj': case 'rg': case 'telefone': case 'cep': case 'placa':
        return (
          <BoxContainer>
            <FotoCheckBox />
            <GaleriaCheckBox />
          </BoxContainer>
        )
      case 'data': case 'hora':
        return (
          <BoxContainer>
            <ComentCheckBox />
          </BoxContainer>
        )
      default: return (<></>)
    }
  }

  return (
    <>
      <FormSubItemHelpModal
        helpModal={helpModal}
        setHelpModal={setHelpModdal}
        setIsHidden={setIsHidden}
        setSubItem={setHelp}
        help={help || null}
        isEditing={isEditing}
      />
      {!isHidden && (
        <Card>
          <TitleText>
            <div className={`${editSubModalOpen ? "modal is-active" : "modal"}`}>
              <div className="modal-background" onKeyDown={(e) => {
                trackEvent({
                  category: 'sample-page',
                  action: 'click-event',
                  name: `fecha modal`,
                })
                escape(e)
              }} onClick={() => {
                trackEvent({
                  category: 'sample-page',
                  action: 'click-event',
                  name: `fecha modal`,
                })
                setEditSubModalOpen(false)
              }} />
              <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
                <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
                  <GenericHeader>
                    {t(key + 'subitemConfig')}
                  </GenericHeader>
                </header>
                <ModalCardbody className="modal-card-body" style={{ minHeight: '500px', display: 'flex', flexWrap: 'wrap', flex: 1 }}>
                  <form>
                    <div className='columns' style={{ marginTop: '20px' }}>
                      <div className='column is-half'>
                        <BoxContainer>
                          <TextField
                            label={t(key + 'subitemName')}
                            disabled={!isEditing}
                            value={nome}
                            onChange={(e) => {
                              trackEvent({
                                category: 'sample-page',
                                action: 'type-event',
                                name: `edita nome`,
                              })
                              setNome(e.target.value)
                            }}
                          />
                          <label className='checkbox'>
                            <input type="checkbox"
                              disabled={!isEditing}
                              checked={preenchimento}
                              onChange={() => {
                                trackEvent({
                                  category: 'sample-page',
                                  action: 'click-event',
                                  name: `habilita preenchimento obrigatorio`,
                                })
                                setPreenchimento(!preenchimento)
                              }}
                            />
                            {t(key + 'mandatory')}
                          </label>
                          <SubItemTypeComponent
                            tipoSelecao={tipoSelecao}
                            subItemType={subItemType}
                            avaliacao={avaliacao || null}
                            setAvaliacao={setAvaliacao}
                            threshold={threshold}
                            setThreshold={setThreshold}
                            naoAplica={naoAplica}
                            padrao={padrao}
                            setPadrao={setPadrao}
                            selecao={selecao}
                            setSelecao={setSelecao}
                            padraoLista={padraoLista}
                            setPadraoLista={setPadraoLista}
                            maxNumberVal={maxNumberVal}
                            setMaxNumberVal={setMaxNumberVal}
                            minNumberVal={minNumberVal}
                            setMinNumberVal={setMinNumberVal}
                            disable={disable}
                            setDisable={setDisable}
                            subitem={subItem}
                            isEditing={isEditing}
                            form={form}
                          />
                        </BoxContainer>
                      </div>
                      <div className='column is-half'>
                        <BoxContainer style={{ height: 'fit-content' }}>
                          <label>{t(key + 'questionType')}</label>
                          <select
                            className='select'
                            disabled={!isEditing}
                            onChange={(e) => {
                              if (e.target.value === 'avaliacao') {

                                setAvaliacao([{ nome: '1', valor: 0 }, { nome: '2', valor: 1 }])
                                setPeso(1)
                                setPadraoLista(null)
                                setSelecao(null)
                              } else if (e.target.value === 'multiple' || e.target.value === 'unique') {
                                setSelecao(["0", "1"])
                                setAvaliacao(null)
                              } else {
                                setAvaliacao(null)
                                setSelecao(null)
                              }
                              setSubItemType(e.target.value)
                              trackEvent({
                                category: 'sample-page',
                                action: 'select-event',
                                name: `seleciona tipo subitem`,
                              })
                            }}
                            defaultValue={subItemType}
                          >
                            {Object.keys(optionsSelectMap).map((e, i) => (
                              <option key={i} value={e}>{optionsSelectMap[e as keyof typeof optionsSelectMap]}</option>
                            ))}
                          </select>
                          {(subItemType === 'unique' || subItemType === 'multiple') &&
                            <>
                              <label>Escolha o tipo de seleção</label>
                              <select disabled={!isEditing} defaultValue={tipoSelecao}
                                onChange={(event) => {
                                  trackEvent({
                                    category: 'sample-page',
                                    action: 'select-event',
                                    name: `seleciona tipo de seleção`,
                                  })
                                  setTipoSelecao(event.target.value as TipoSelecaoType)
                                }}>
                                {Object.keys(mapTiposSelecao).map((tipo, index) => (
                                  <option key={index} value={tipo}>{mapTiposSelecao[tipo as keyof typeof mapTiposSelecao]}</option>
                                ))}
                              </select>
                            </>
                          }
                          <h1>{`${t('options')}:`}</h1>
                        </BoxContainer>
                        {switchTypes(subItemType)}
                      </div>
                    </div>
                    <ButtonsContainer>
                      {isEditing && 
                      <button className='button is-success is-small' onClick={handleSaveBtn} disabled={checkDisable()}>{t('save')}</button>}
                      <button className='button is-warning is-small' onClick={() => {
                        const avaString = localStorage.getItem('avaliacaoBackup');
                        const seleString = localStorage.getItem('selecaoBackup');
                        if (avaString && subItem.tipo === 'avaliacao') {
                          const avaliacaoBackup = JSON.parse(avaString);
                          subItem.avaliacao = avaliacaoBackup;
                          setAvaliacao(avaliacaoBackup || null);
                        } else if (seleString && (subItem.tipo === 'multiple' || subItem.tipo === 'unique')) {
                          const selecaoBackup = JSON.parse(seleString);
                          subItem.selecao = selecaoBackup;
                          setSelecao(selecaoBackup);
                        }
                        setSubModalOpen(false)
                        setEditSubModalOpen(false)
                      }}>{isEditing ? t('cancel') : t('close')}</button>
                      {!form.preenchimentoWeb &&
                        <button
                          className='button is-light is-small'
                          onClick={() => {
                            setIsHidden(true);
                            setHelpModdal(true);
                          }}
                        >
                          {!isEditing ? 'Visualizar ajuda' : `${t(key + 'helpConfig')}`}
                        </button>}
                    </ButtonsContainer>
                  </form>
                </ModalCardbody>
              </ModalCard>
            </div>
          </TitleText>
        </Card>
      )}
    </>
  )
}
