import { useContext, useEffect, useState } from 'react'
import { ChamadoChecklist } from './Types';
import { Accordion, AccordionSummary } from '@material-ui/core';
import { WebFormContext } from '../../context/WebFormContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { StyledTextArea } from './Modals/Styles';
import ReactDatePicker from 'react-datepicker';

type props = {
  chamado: ChamadoChecklist,
  index: number,
  subItemIndex: number,
  itemIndex: number
}

export default function ChamadoCardSubItem({ chamado, index, subItemIndex, itemIndex }: props) {
  const { webForm, setWebForm } = useContext(WebFormContext);
  const item = webForm.itens[itemIndex];
  const subItem = item.subitens[subItemIndex];
  const [instrucao, setInstrucao] = useState(subItem.chamados ? subItem.chamados[index].textChamado || '' : '');
  const [data, setData] = useState<Date | number | undefined>(undefined);
  const [expand, setExpand] = useState(chamado.shown || false);

  useEffect(() => {
    if (subItem.chamados) {
      subItem.chamados[index].dateChamado = data
      webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
    }
  }, [])

  return (
    <div key={index} style={{ margin: '10px', backgroundColor: '#F2F2F2' }}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => {
              setExpand(!expand)
              if (subItem.chamados) {
                subItem.chamados[index].shown = !expand
                webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
                setWebForm({ ...webForm })
              }
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h1>{chamado.selecionado ? chamado.selecionado.nome : 'Nome não encontrado'}</h1>
            <AiFillCloseCircle color='red' onClick={() => {
              if (subItem.chamados) {
                subItem.chamados = subItem.chamados.filter((_el, i) => i !== index)
                if (subItem.chamados.length === 0) delete subItem.chamados;
                webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
                setWebForm({ ...webForm })
              }
            }} />
          </div>
        </AccordionSummary>
        <div style={{ padding: '10px' }}>
          <Label style={{ display: 'flex', flexDirection: 'column' }}>
            Instruções do chamado:
            <StyledTextArea value={instrucao} className='textarea' onChange={(e) => {
              setInstrucao(e.target.value)
              if (subItem.chamados) {
                subItem.chamados[index].textChamado = e.target.value;
                webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
                setWebForm({ ...webForm })
              }
            }} />
          </Label>
          <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Prazo do chamado:
            {/* subitem em execução */}
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              showTimeSelect
              timeIntervals={1}
              portalId="root-portal"
              selected={data === undefined ? undefined : new Date(data)}
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='inputDate input'
              required
              onKeyDown={(e) => e.preventDefault()}
              onChange={
                (date: Date) => {
                  setData(date);
                  if (subItem.chamados) {
                    subItem.chamados[index].dateChamado = date;
                    webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
                    setWebForm({ ...webForm })
                  }
                }
              }
            />
          </Label>
          {data === undefined && <span
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              color: 'red',
              fontWeight: 700,
              fontSize: '12px'
            }}>
            **É obrigatório informar uma data para abertura do chamado
          </span>}
        </div>
      </Accordion>
    </div>
  )
}
