import { useEffect, useState } from 'react';
import { LabelAvaliacao, MainContainerAvaliation, OptionsContainer } from './AvaliacaoStyle';
import { Container } from '../Unique/UniqueStyle';
import { SubItemFormFilling } from '../../../Types';
import SubItemPhoto from '../SubItemPhoto';

export interface SubItemProps {
  subItem: SubItemFormFilling
  indexItem?: number,
  onChange?: () => void,
}

const Avaliacao = ({ subItem, indexItem, onChange }: SubItemProps) => {
  const [ocorrencia, setOcorrencia] = useState(subItem.ocorrencia || '');

  useEffect(() => {
    if (subItem.padrao && !subItem.checked) {
      subItem.nota = Number(subItem.padrao);
      subItem.checked = true;
      onChange && onChange();
    }
  }, [subItem])

  const subItemOk = '0.3em #4caf50 solid'
  const subItemPendente = '0.3em #2196f3 solid'
  const subItemNotOk = '0.3em red solid'

  const borderCheckerComent = () => {
    if (!subItem.hideComent) {
      if (subItem.ocorrencia) {
        if (subItem.ocorrencia && subItem.ocorrencia?.length > 0) {
          return subItemOk
        }
      }
      if ((subItem.nota || subItem.nota === 0) && (subItem.threshold || subItem.threshold === 0)) {
        if (subItem.nota === -1) {
          if (subItem.obrigatoriedade?.NA?.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemNotOk
          } if (!subItem.obrigatoriedade?.NA?.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemPendente
          }
        } if (subItem?.nota > subItem?.threshold) {
          if (subItem.obrigatoriedade?.conforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemNotOk
          } if (!subItem.obrigatoriedade?.conforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemPendente
          }
        } if (subItem.nota <= subItem?.threshold) {
          if (!subItem.hideComent && subItem.obrigatoriedade?.naoConforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemNotOk
          } if (!subItem.obrigatoriedade?.naoConforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return subItemPendente
          }
        }
      }
    }
    return subItemPendente
  }

  return (
    <MainContainerAvaliation>
      <Container>
        <OptionsContainer>
          {subItem.avaliacao ? (
            subItem.avaliacao.map((ava, i) => (
              <LabelAvaliacao
                key={ava.nome}>
                <input
                  defaultChecked={subItem.padrao && Number(subItem?.padrao) === i}
                  value={i}
                  onChange={(e) => {
                    subItem.nota = Number(e.target.value);
                    subItem.checked = true;
                    onChange && onChange();
                  }}
                  type='radio'
                  name={subItem.ocorrenciaId || `${subItem.nome}${subItem.id}`}
                />&nbsp;
                <span>{ava.nome}</span>
              </LabelAvaliacao>
            ))
          ) : <></>}
        </OptionsContainer>
        {typeof subItem.nota === 'number' &&
          <>
            {(!subItem.hideComent) &&
              <input
                className='input'
                value={ocorrencia}
                style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em' }}
                onChange={(e) => { setOcorrencia(e.target.value); subItem.ocorrencia = e.target.value }} />
            }
            {!subItem.hideImg &&
              <SubItemPhoto onChange={onChange} subItem={subItem} indexItem={indexItem} />
            }
          </>
        }
      </Container>
    </MainContainerAvaliation >
  )
}

export default Avaliacao