import { Accordion, AccordionSummary, Card, TextField } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { GenericHeader, TagContainer, TagSpan } from '../../../StyledComponents/Modal/generic'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { Input } from '../../../StyledComponents/Input/generic'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import AttToken from '../../../helpers/attToken'
import TicketsAndNotifications, { notificationType, typeToCreate, typeToSend } from '../../../service/Tickets_Notifications'
import { toast } from 'react-toastify'
import queryClient from '../../../service/query'
import { SimpleTeam } from '../../../service/Teams'
import { simpleFormType } from '../../../service/Checklists'
import AssociateTeamPlaceNotifi from '../AssociateTeamPlaceNotifi'
import { FaArrowRight } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { Autocomplete, Skeleton } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type props = {
  isEditing: boolean,
  notification: notificationType,
  id: number,
  teams: SimpleTeam[],
  forms: simpleFormType[],
  isLoadingTeams: boolean,
  isLoadingForms: boolean,
  refetNotifications: Function
}

export default function AddNotificationModal({
  isEditing,
  notification,
  id,
  teams,
  forms,
  isLoadingForms,
  isLoadingTeams,
  refetNotifications,
}: props
) {
  const { popUp, openModal } = useContext(UserContext);

  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);

  const { t } = useTranslation('translation');

  const [editNotification, setEditNotification] = useState<Omit<typeToSend, 'prazo' | 'prazoHoras'>>({
    nome: '',
    mensagem: '',
    equipes: [],
    remetente: '',
    formularios: [],
    tipo: 'notificacao',
    id: 0,
    equipeLocal: null,
    notifiEm: '',
    encaminha: null
  });

  const [equipeSelecionada, setEquipeSelecionada] = useState('');
  const [showingAssociation, setShowingAssociation] = useState(false);
  const [notifiEm, setNotifiEm] = useState('sempre');

  const { mutate: create, isLoading: loadingCreate } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToCreate = {
          mensagem: editNotification.mensagem,
          nome: editNotification.nome,
          tipo: editNotification.tipo,
          equipes: editNotification.equipes,
          formularios: null,
          remetente: editNotification.remetente || null,
          prazo: null,
          prazoHoras: null,
          notifiEm
        }
        const response = await NotificationsService.createTypes(token, tipeObject)
        toast.warn(`${t('notifications.waitNoti')}`);
        return response;
      }
    },
    onSuccess: () => {
      openModal();
      queryClient.resetQueries(['GET_NOTIFICATIONS'])
      toast.success(`${t('notifications.notifCreated')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.errorNotif')}`);
    }
  })

  const { mutate: edit, isLoading: loadingEdit } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editNotification.mensagem,
          nome: editNotification.nome,
          equipes: editNotification.equipes,
          tipo: editNotification.tipo,
          remetente: editNotification.remetente || null,
          formularios: editNotification.formularios,
          prazo: null,
          prazoHoras: null,
          notifiEm,
          equipeLocal: notification.equipeLocal || null,
          id: notification.id,
          encaminha: null
        }
        const response = await NotificationsService.updateTypes(token, id, tipeObject)
        toast.warn(`${t('notifications.waitEditNot')}`);
        refetNotifications()
        return response;
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['getNotifications'])
      openModal();
      toast.success(`${t('notifications.notifEdit')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.errorEditNotif')}`);
    }
  })

  useEffect(() => {
    if (isEditing) {
      setEditNotification({
        ...notification
      })
      setNotifiEm(notification.notifiEm || '')
    }
  }, [isEditing, notification])

  const findTeamsName = (value: string) => {
    const teamName = teams && teams.find((el: SimpleTeam) => el.id === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }


  const optionsNotifi = {
    sempre: 'Sempre',
    nunca: 'Nunca',
    naoConformidade: 'Apenas em caso de não conformidade'
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => { }} onClick={() => openModal()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {isEditing ?
                  t('notificationsAndTickets.notifications.editNotification') :
                  t('notificationsAndTickets.notifications.createNotification')}
              </GenericHeader>
            </header>
            <div style={{ justifyContent: 'space-around' }} className="modal-card-body">
              {isLoadingTeams || isLoadingForms ? (
                <>
                  <Skeleton variant='rounded' height={30} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={30} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={60} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={60} sx={{ marginBottom: '10px' }} />
                  <Skeleton variant='rounded' height={30} sx={{ marginBottom: '10px' }} />
                </>
              ) :
                <>
                  {showingAssociation &&
                    <AssociateTeamPlaceNotifi
                      equipe={equipeSelecionada}
                      tipoLocalAssocia={notification.equipeLocal}
                      setShowingAssociation={setShowingAssociation}
                      nomeEquipe={findTeamsName(equipeSelecionada)}
                      chamado={notification}
                      forms={forms}
                      teams={teams}
                    />}
                  {!showingAssociation && <>
                    <TextField
                      style={{ marginBottom: '10px' }}
                      fullWidth
                      value={editNotification.nome}
                      label={t('name')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditNotification({ ...editNotification, nome: e.target.value })}
                    />
                    <Label>{t('notificationsAndTickets.tickets.Notificar_em')}:</Label>
                    <select
                      onChange={(e) => setNotifiEm(e.target.value)}
                      value={notifiEm}
                      className='select is-fullwidth is-small'
                    >
                      {Object.keys(optionsNotifi).map(e => (
                        <option value={e}>{optionsNotifi[e as keyof typeof optionsNotifi]}</option>
                      ))}
                    </select>
                    {isEditing && <>
                      <Label>{t('team')}</Label>
                      <Accordion style={{ padding: '0px 15px 10px 15px', marginBottom: '10px' }}>
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}>
                          <Label>{t('team')}</Label>
                        </AccordionSummary>
                        <TagContainer>
                          {editNotification.equipes && editNotification.equipes.map((e: string, i) => (
                            <div className='columns' style={{ margin: '0px .1em 0px 0px', display: 'flex', width: '100%', alignItems: 'center' }}>
                              <div className='column' style={{ margin: 0, padding: 0 }}>
                                <TagSpan
                                  style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}
                                  key={i}
                                  onClick={() => {
                                    setEditNotification({ ...editNotification, equipes: editNotification.equipes.filter(el => el !== e) })
                                  }}>
                                  {findTeamsName(e)}&nbsp;&nbsp;
                                  <IoIosClose />
                                </TagSpan>
                              </div>
                              <button
                                className="button is-success is-small"
                                onClick={() => {
                                  setEquipeSelecionada(e)
                                  if (notification.equipeLocal && !notification.equipeLocal.some(el => el.equipe === e) && isEditing) {
                                    notification.equipeLocal = [...notification.equipeLocal, { equipe: e, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                                  } else if (isEditing && !notification.equipeLocal) notification.equipeLocal = [{ equipe: e, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                                  setShowingAssociation(true);
                                }}
                              >
                                {`Locais`}&nbsp;<FaArrowRight />
                              </button>
                            </div>
                          ))
                          }
                        </TagContainer>
                        <Autocomplete
                          multiple
                          value={editNotification?.equipes?.map((ele) => (
                            {
                              value: ele,
                              label: teams?.find((elem) => elem.id === ele)?.nome
                            }
                          )) || null}
                          id="combo-box-demo"
                          sx={{ marginTop: '15px' }}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          filterSelectedOptions
                          disableClearable
                          options={
                            teams
                              ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                              ?.map((ele: any) => ({
                                label: ele.nome,
                                value: ele.id,
                              }))}
                          onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                            const value = details?.option.value;
                            if (editNotification.equipes.includes(value)) {
                              setEditNotification({ ...editNotification, equipes: editNotification.equipes.filter((ele) => ele !== value) })
                            } else {
                              setEditNotification({ ...editNotification, equipes: [...editNotification.equipes, value] })
                            }
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.value}>
                              <span>{option.label}</span>
                            </li>)}
                          renderInput={(params) => <TextField {...params} label={t('team')} />}
                        />
                      </Accordion>
                    </>}
                    <TextField
                      style={{marginBottom:'10px'}}
                      fullWidth
                      rows={2}
                      multiline
                      maxRows={4}
                      onChange={(e) => setEditNotification({ ...editNotification, mensagem: e.target.value })}
                      value={editNotification.mensagem}
                      label={t("message")}
                    />
                    <TextField
                      fullWidth
                      value={editNotification.remetente as string}
                      label={t('notificationsAndTickets.notifications.optionalSender')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditNotification({ ...editNotification, remetente: e.target.value })}
                    />
                  </>}
                </>}
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                disabled={(loadingEdit || loadingCreate) || !editNotification.mensagem?.length}
                type='submit'
                className="button is-success is-small"
                onClick={isEditing ? () => edit() : () => create()}
              >
                {loadingCreate || loadingEdit ? t('saving') : `${t('save')}`}
              </button>
              <button
                disabled={loadingCreate || loadingEdit}
                type="submit"
                onClick={() => openModal()}
                className="button is-small is-warning"
              >
                {loadingCreate || loadingEdit ? t('saving') : t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
