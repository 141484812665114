import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import Companies from '../../service/Companies';
import { Form, GreenButton } from '../../StyledComponents/Input/generic';
import AttToken from '../../helpers/attToken';
import { Skeleton, TextField } from '@mui/material';

const companiesSrvc = new Companies();

export type HierarchyLabel = {
  cliente: string,
  local: string,
  conjunto: string,
  unidade: string,
  gerencia: string,
}

const initialHierarchy = {
  cliente: '',
  local: '',
  conjunto: '',
  unidade: '',
  gerencia: '',
}

export default function HierarchyConfig() {
  const { hierarchyLabel, setHierarchyLabel } = useContext(UserContext);
  const [hierarchy, setHierarchy] = useState(initialHierarchy);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('translation');

  const editLabels = async () => {
    setLoading(true);
    try {
      const token = await AttToken()
      if (token) {
        const { message } = await companiesSrvc.editHierarchyLabel({ ...token, empresa: token.userEmpresa, labels: { ...hierarchy, default: false } });
        message && toast.success(message);
      }

    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }

  const getAllFunc = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        const result = await companiesSrvc.getHierarchyLabels({ ...token, empresa: token.userEmpresa });
        setHierarchy(result);
      }
    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getAllFunc();
  }, [loading, getAllFunc])

  return (
    <div>
      <Form onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        editLabels();
        setHierarchyLabel(hierarchy);
        setHierarchy(initialHierarchy);
      }}>
        <h1>{t('settingsPage.hierarchy.chooseLabel')}</h1>
        {loading
          ? <>
            <Skeleton variant='rounded' height={50} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={50} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={50} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={50} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={50} sx={{ marginTop: '15px' }} />
            <Skeleton variant='rounded' height={30} width={200} sx={{ marginTop: '15px' }} />
          </>
          : <>
            <TextField
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.cliente} - (Cliente)`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, cliente: e.target.value })
              }}
              value={hierarchy.cliente}
            />
            <TextField
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.local} - (Local)`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, local: e.target.value })
              }}
              value={hierarchy.local}
            />
            <TextField
              sx={{ width: '100%', marginTop: '15px' }}
              label={`${hierarchyLabel.conjunto} - (Conjunto)`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, conjunto: e.target.value })
              }}
              value={hierarchy.conjunto}
            />
            <TextField
              sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}
              label={`${hierarchyLabel.unidade} - (Unidade)`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, unidade: e.target.value })
              }}
              value={hierarchy.unidade}
            />
            <TextField
              sx={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}
              label={`${hierarchyLabel.gerencia ? hierarchyLabel.gerencia : ''} - (Gerência)`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHierarchy({ ...hierarchy, gerencia: e.target.value })
              }}
              value={hierarchy.gerencia}
            />
            <GreenButton type='submit' disabled={
              hierarchy.cliente?.length < 3 ||
              hierarchy.local?.length < 3 ||
              hierarchy.conjunto?.length < 3 ||
              hierarchy.unidade?.length < 3 ||
              hierarchy.gerencia?.length < 3
            }
              className="button is-success is-small"
            >
              {t('settingsPage.hierarchy.changeBtn')}
            </GreenButton>
          </>
        }
      </Form>
    </div>
  )
}
