import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AiFillQuestionCircle, AiOutlineLink } from 'react-icons/ai';
import { ChecklistHelpType } from '../../../service/Checklists';
import { GenericHeader, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic'
import { Card, TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { ButtonsContainer, HelpModalContainer, InfoDiv } from './FormModal/FormModalStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ModalImagePreview from '../../Reports/ModalDetailPrint/ModalImagePreview';

const key = 'checklists.subitemModal.helpModal.'

export default function FormSubItemHelpModal({
  helpModal,
  setHelpModal,
  setIsHidden,
  setSubItem,
  help,
  isEditing
}
  : {
    helpModal: boolean,
    setHelpModal: Function,
    setIsHidden: Function,
    setSubItem: Function,
    help: ChecklistHelpType | null,
    isEditing: boolean
  }) {


  const { t } = useTranslation('translation');

  const [state, setState] = useState<ChecklistHelpType>({
    choose: '',
    video: '',
    comentario: '',
    foto: '',
    newPhoto: false
  })
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string>('');

  const handleClose = () => {
    setIsHidden(false);
    setHelpModal(false);
  }

  const handleSave = () => {
    setSubItem(state);
    setIsHidden(false);
    setHelpModal(false);
  }

  useEffect(() => {
    help && setState({
      choose: help.choose,
      video: help.video,
      comentario: help.comentario,
      foto: help.foto,
      newPhoto: !!help.newPhoto
    })
  }, [help])

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setState({ ...state, foto: i, newPhoto: true })));
    }
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${helpModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" />
          <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {t(key + 'helpCamp')}
              </GenericHeader>
            </header>
            <ModalCardbody className="modal-card-body">
              <HelpModalContainer>
                <InfoDiv>
                  <AiFillQuestionCircle color={'darkblue'} />
                  <p>{t(key + 'useThisConfig')}</p>
                </InfoDiv>
                <h2>{t(key + 'visualInfo')}</h2>
                <div className='columns' style={{ width: '100%' }}>
                  <div className='column is-half'
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <div style={{ display: 'flex' }}>
                      <input
                        disabled={!isEditing}
                        value={'foto'}
                        type="checkbox"
                        checked={state.choose === 'foto'}
                        onChange={(e) => {
                          setState({ ...state, choose: e.target.value })
                        }}
                      />
                      <span style={{ fontSize: '13px', fontWeight: '600' }}>
                        &nbsp;   {t(key + 'sendPhoto')}
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <input
                        disabled={!isEditing}
                        type="checkbox"
                        value={'video'}
                        checked={state.choose === 'video'}
                        onChange={(e) => {
                          setState({ ...state, choose: e.target.value })
                        }}
                      />
                      <span style={{ fontSize: '13px', fontWeight: '600' }}>
                        &nbsp; {t(key + 'videoIndicate')}
                      </span>
                    </div>
                  </div>
                  <div className='column is-half'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    {state.choose === 'video' || '' ?
                      <>
                        <div style={{ display: 'flex' }}>
                          <div className='button is-small is-link'>
                            <AiOutlineLink />
                          </div>
                          <input
                            disabled={!isEditing}
                            type="text"
                            value={state.video}
                            onChange={(e) => {
                              setState({ ...state, video: e.target.value })
                            }}
                            placeholder="www.site.com/video"
                            className='input is-small is-fullwidth'
                          />
                        </div>
                      </> :
                      <>
                        {(state.foto !== '') ?
                          <>
                            <div>
                              <div style={{ height: '150px', width: '80%', display: 'flex' }} className='column is-2'>
                                {open && <ModalImagePreview
                                  image={image}
                                  open={open}
                                  setOpen={setOpen}
                                />}
                                <img
                                  style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative'
                                  }}
                                  onClick={(e) => {
                                    setOpen(true)
                                    setImage(String(state.foto))
                                  }}
                                  src={state.foto}
                                  alt=''
                                />
                                <button
                                  disabled={!isEditing}
                                  style={{ position: 'absolute' }}
                                  value={state.foto}
                                  onClick={() => setState({ ...state, foto: '' })}
                                  className="button is-small is-danger">
                                  <DeleteIcon />
                                </button>
                              </div>
                            </div>
                          </> :
                          state.choose === 'foto' &&
                          <label
                            style={{ display: 'flex', alignItems: 'center' }}
                            className="file button is-small is-success"
                          >
                            <span style={{ margin: 0, padding: 0 }} className="file-cta">
                              <UploadFileIcon />
                              <span className="file-label">
                              </span>
                              Escolher foto...
                            </span>
                            <input
                              disabled={!isEditing}
                              accept="image/png, image/jpeg"
                              value={undefined}
                              id="img"
                              name="img"
                              multiple={true}
                              className="file-input"
                              type="file"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => renderImages(e.target.files)}
                            />
                          </label>
                        }

                      </>}
                  </div>
                </div>
                <h2>{t(key + 'writtenDesc')}</h2>
                <textarea
                  disabled={!isEditing}
                  className="textarea is-success"
                  placeholder={t(key + 'insertInfo')}
                  value={state.comentario}
                  onChange={(event) => setState({ ...state, comentario: event.target.value })}
                  style={{ marginBottom: '50px' }}
                />
              </HelpModalContainer>
              <ButtonsContainer>
                {isEditing && <button className='button is-success is-small' onClick={handleSave}>{t('save')}</button>}
                <button className='button is-warning is-small' onClick={handleClose}>{isEditing? t('cancel') : t('close')}</button>
              </ButtonsContainer>
            </ModalCardbody>
          </ModalCard>
        </div>
      </TitleText>
    </Card>
  )
}
