import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type ManagesType = {
  nome: string,
  empresa: string,
  clientes?: string[],
  formularios?: string[],
  gerentes?: string[],
  equipes?: string[],
  dataCriacao: number,
  dataDelecao?: number,
  dataEdicao: number,
  id: string,
  users: string[],
}

class Manages {
  private api = axios.create({
    baseURL:
    `${process.env.REACT_APP_API_HOST}/gerencias`,
  });

  getAllManages = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getAll",
        {
          ...body,
        }
      );
      return data.data.gerencias;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createManage = async (body: AuthBodyType & {nome: string}) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/create",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateManage = async (body: AuthBodyType & {
    nome?: string,
    formularios?: string[],
    gerentes?: string[],
    equipes?: string[],
    dataDelecao?: number | boolean,
    users?: string[],
    id: string,
    clientes?:string[],
  }) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/edit",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default Manages;
