import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";
import { conjuntoAssociadoTree } from "./Checklists";

export type TeamServiceTypes = {
  tipo: string;
  conjuntosAssociados: conjuntoAssociadoTree[] | null;
  cnjs: string[] | null;
}

export type memberType = {
  id: string,
  nome: string
}

export type SimpleTeam = {
  nome: string,
  id: string,
  checklistsAssociados: string[] | null,
  membros: memberType[]
}

export type Priority = 'baixa' | 'media' | 'alta'

export type TreatmentTypes = {
  tipo: string,
  cnjs: string[],
  prioridade: Priority[]
}

export type NotificaSobre = {
  tipo: string,
  cnjs: string[]
}

export type Team = {
  id: string,
  teamID: string,
  dataAlteracao: number;
  dataCadastro: number;
  empresa: string;
  gerente: string;
  listaCliente?: string[];
  nome: string;
  status: boolean;
  tiposServico: string[];
  tiposLocaisAssocia: TeamServiceTypes[] | null;
  users: {
    [nome: string]: {
      userAlias: string;
    };
  };
  gerencia?: string ;
  tiposAtendimento: TreatmentTypes[],
  notificaSobre: NotificaSobre[],
};

class Teams {
  private api = axios.create({
    baseURL:
    `${process.env.REACT_APP_API_HOST}/equipes`,
  });

  getAll = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getAllByCurrentUser",
        {
          ...body,
        }
      );
      return data.data.teams;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getBasicUsersInfoByTeam = async (
    body: AuthBodyType,
    team: string
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getBasicUsersInfoByTeam",
        {
          ...body,
          team,
        }
      );
      return data.data.usersInfo;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  addTypeOnTeam = async (
    body: AuthBodyType & {
      typeId: string;
      equipeId: string[];
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/addType",
        {
          ...body,
        }
      );
      return data.data.usersInfo;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getTeamById = async (
    body: AuthBodyType & { equipeId: string }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getTeamById",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  updateTeam = async (
    body: AuthBodyType,
    equipeId: string,
    equipe: {
        listaCliente?: string[];
        nome?: string;
        status?: boolean;
        tiposServico?: string[];
        users?: string[];
      }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/changeTeam",
        {
          ...body,
          equipeId,
          equipe,
        }
      );
      return data.data.usersInfo;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  createTeam = async (
    body: AuthBodyType,
    teamName: string,
    users: string[],
    tiposServico?: string[],
    
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/newTeam",
        {
          ...body,
          teamName,
          users,
          tiposServico,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };

  getSimpleTeams = async (
    body: AuthBodyType,
    
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getSimpleTeams",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error('Falha!');
        return error.response?.status;
      }
    }
  };
}

export default Teams;
