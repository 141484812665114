import { useMutation } from '@tanstack/react-query'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import AttToken from '../../../helpers/attToken'
import { useMemo, useState } from 'react';
import Aprovations from '../../../service/Aprovations';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import { AxiosError } from 'axios';
import { ChecklistReportType } from '../../../service/Reports';

export default function ModalAprovation({
  openAprovation,
  setOpenAprovation,
  aprove,
  setAprove,
  checklist,
  usuarioExecutor,
  setOpenModal,
  selected
}: {
  openAprovation?: boolean,
  setOpenAprovation?: Function,
  aprove: string,
  setAprove?: Function,
  checklist: number,
  usuarioExecutor: string,
  setOpenModal?: Function,
  selected?: ChecklistReportType
}) {

  const aprovationService = useMemo(() => new Aprovations(), []);
  const [comment, setComment] = useState('')

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_APROVATION'],
    mutationFn: async () => {
      try {
        toast.warn('Aguarde salvando!')
        const token = await AttToken();
        if (token) {
          const response = await aprovationService.updateAprovation(
            token,
            comment,
            aprove,
            usuarioExecutor,
            checklist,
          )
          if (response.error) {
           return 'Erro ao aprovar formulário'
          }
          return response
        }
      } catch (err) {
        console.log(err)
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_APROVATIONS'])
      setOpenModal && setOpenModal(false)
      toast.success(`Formulário ${aprove}`)
    },
    onError: () => {
      console.log(AxiosError)
    }
  })


  return (
    <div className={`modal ${openAprovation ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header style={{ display: 'flex', justifyContent: 'center' }} className="modal-card-head">
          <GenericHeader>
            {aprove === 'aprovado' ? 'Aprovar' : 'Reprovar'} Formulário
          </GenericHeader>
        </header>
        <section className="modal-card-body">
          <span style={{ fontSize: '.7em' }}>
            Atenção, essa ação não poderá ser desfeita!<br />
            Adicione uma observação sobre o motivo da {aprove === 'aprovado' ? 'aprovação' : 'reprovação'}.
          </span>
          <input
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className='input' placeholder='Adicione observação' />
        </section>
        <footer className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            disabled={selected?.mandatory ? comment.length ? false : true : false}
            onClick={(e) => {
              e.preventDefault()
              mutate()
            }}
            className="button is-success"
          >Sim</button>
          <button className="button is-danger" type="button" onClick={() => {
            setOpenAprovation && setOpenAprovation(false)
            setAprove && setAprove('')
          }}>Não</button>
        </footer>
      </div>
    </div>
  )
}
